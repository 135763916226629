export const translations = {
  en: {
    translation: {
      'Discourse Sign In': 'Discourse sign in',
      'Oops...': 'Oops...',
      'Continue with Google': 'Continue with Google',
      'Sign in with Trimble': 'Sign in with Trimble',
      'Continue with Trimble': 'Continue with Trimble',
      'Sign in with Facebook': 'Sign in with Facebook',
      'Simple, fast, smart cloud rendering': 'Simple, fast, smart cloud rendering',
      'Start with 20 free credits': 'Start with 20 free credits',
      'A global computer graphics event for 3D artists and developers':
        'A global computer graphics event for 3D artists and developers',
      'Get your ticket': 'Get your ticket',
      'Epic CG event returns to Sofia': 'Epic CG event returns to Sofia',
      'Early Bird tickets out now': 'Early Bird tickets out now',
      'Get tickets': 'Get tickets',
      'New user?': 'New user?',
      'Create Account': 'Create account',
      'Sign in': 'Sign in',
      'Please wait...': 'Please wait...',
      Password: 'Password',
      'Forgot your password?': 'Forgot your password?',
      'Resend activation link': 'Resend activation link',
      'A new activation link has been sent to': 'A new activation link has been sent to',
      'Please use it to activate your account.': 'Please use it to activate your account.',
      'Your account is active': 'Your account is active',
      'Welcome to Chaos!': 'Welcome to Chaos!',
      'Welcome to Corona!': 'Welcome to Corona!',
      'Delete account': 'Delete account',
      'Are you sure you want to delete all data associated with your account?':
        'Are you sure you want to delete all data associated with your account?',
      'Delete all data': 'Delete all data',
      'Thank you!': 'Thank you!',
      'All data was successfully deleted.': 'All data was successfully deleted.',
      'We apologize for any inconvenience.': 'We apologize for any inconvenience.',
      'Something went wrong.': 'Something went wrong.',
      'Having troubles?': 'Having troubles?',
      'If you experience problems, contact our': 'If you experience problems, contact our',
      'Customer Service.': 'Customer service.',
      'Already have an account?': 'Already have an account?',
      'Back to profile': 'Back to profile',
      'Change your password': 'Change your password',
      'Old password': 'Old password',
      'New password': 'New password',
      'Change Password': 'Change Password',
      'Password successfully updated. Redirecting you to login page...':
        'Password successfully updated. Redirecting you to login page...',
      "Enter your email and we'll": "Enter your email and we'll",
      'send you instructions to reset your password': 'send you instructions to reset your password',
      Email: 'Email',
      Recover: 'Recover',
      "with a link to reset your password. If you don't see the email, please check your spam folder.":
        "with a link to reset your password. If you don't see the email, please check your spam folder.",
      'Back to login': 'Back to login',
      'LOADING...': 'Loading...',
      'Request a new one': 'Request a new one',
      'Set your new password': 'Set your new password',
      '(12 or more characters)': '(12 or more characters)',
      Update: 'Update',
      'Set your password': 'Set your password',
      'SET PASSWORD': 'Set password',
      'Leave page?\nChanges that you made may not be saved.': 'Leave page?\nChanges that you made may not be saved.',
      Logout: 'Logout',
      'Edit profile': 'Edit profile',
      'Your profile was updated!': 'Your profile was updated!',
      'Go back to': 'Go back to',
      'chaos website': 'Chaos website',
      'corona website': 'Corona website',
      'enscape website': 'Enscape website',
      'Loading your account...': 'Loading your account...',
      'Loading your details...': 'Loading your details...',
      'First name': 'First name',
      'Last name': 'Last name',
      '(Optional)': '(Optional)',
      'Customer Number': 'Customer number',
      'Save changes': 'Save changes',
      'Change password': 'Change password',
      'Reload captcha': 'Reload captcha',
      'Please enter the numbers on the image': 'Please enter the numbers on the image',
      Back: 'Back',
      'Almost there': 'Almost there',
      'Create account': 'Create account',
      "Let's be friends. It's free.": "Let's be friends. It's free.",
      Next: 'Next',
      'Complete your profile': 'Complete your profile',
      'Country or Region': 'Country or region',
      newsletterSubscription:
        'Send me occasional news about exclusive offers, products, upcoming events, and webinars. You can opt-out any time.',
      'Your account has been created.': 'Your account has been created.',
      "with a link to activate your account. If you don't see the email, please check your spam folder or try":
        "with a link to activate your account. If you don't see the email, please check your spam folder or try",
      sending: 'sending',
      'the email again.': 'the email again.',
      "We've resent the activation code successfully. If you still don't see the email, please":
        "We've resent the activation code successfully. If you still don't see the email, please",
      contact: 'contact',
      'our support team.': 'our support team.',
      'Link existing account': 'Link existing account',
      'Account with this email already exists.': 'Account with this email already exists.',
      Change: 'Change',
      'Enter your Chaos password to link it to your': 'Enter your Chaos password to link it to your',
      'account.': 'account.',
      'Chaos Password': 'Chaos password',
      'LINK ACCOUNTS': 'Link accounts',
      'We will use your Google details to create your Chaos account':
        'We will use your Google details to create your Chaos account',
      'We will use your Facebook details to create your Chaos account':
        'We will use your Facebook details to create your Chaos account',
      'We will use your Trimble details to create your Chaos account':
        'We will use your Trimble details to create your Chaos account',
      Country: 'Country',
      'Link an existing account': 'Link an existing account',
      'CREATE ACCOUNT': 'Create account',
      'Account created': 'Account created',
      'Your Chaos account is now active.': 'Your Chaos account is now active.',
      OK: 'OK',
      'A Chaos account has been created and linked to your': 'A Chaos account has been created and linked to your',
      'Trimble Account.': 'Trimble account.',
      'Fast interactive rendering. New, smart functionality.': 'Fast interactive rendering. New, smart functionality.',
      'Sign in with your Trimble account to start using V-Ray for SketchUp Studio':
        'Sign in with your Trimble account to start using V-Ray for SketchUp Studio',
      'Loading...': 'Loading...',
      '404 – PAGE NOT FOUND': '404 – PAGE NOT FOUND',
      'Please enter your username or email address.': 'Please enter your username or email address.',
      'Invalid activation link.': 'Invalid activation link.',
      'Invalid delete account link.': 'Invalid delete account link.',
      'Please enter your email address.': 'Please enter your email address.',
      'Please enter valid email address.': 'Please enter valid email address.',
      'Invalid password.': 'Invalid password.',
      'This email address is already connected to an account.':
        'This email address is already connected to an account.',
      instead: 'instead',
      'Please enter your password.': 'Please enter your password.',
      'Your password must be at least 8 characters long.': 'Your password must be at least 8 characters long.',
      'Your password must be at most 64 characters long.': 'Your password must be at most 64 characters long.',
      'Both lower and upper case letters': 'Both lower and upper case letters',
      'At least one number': 'At least one number',
      'At least one symbol': 'At least one symbol',
      'At least 12 characters': 'At least 12 characters',
      'Please enter your first name.': 'Please enter your first name.',
      'Your first name cannot be more than 72 characters long.':
        'Your first name cannot be more than 72 characters long.',
      'Please enter your last name.': 'Please enter your last name.',
      'Your last name cannot be more than 53 characters long.':
        'Your last name cannot be more than 53 characters long.',
      'Please enter your country.': 'Please enter your country.',
      'Non-Latin letters': 'Non-Latin letters',
      'No Internet connection. Please check the network cables, modem and router or reconnect to Wi-Fi.':
        'No Internet connection. Please check the network cables, modem and router or reconnect to Wi-Fi.',
      'We are having technical difficulties. Please try later.':
        'We are having technical difficulties. Please try later.',
      'An unexpected error occurred.': 'An unexpected error occurred.',
      'Username or email': 'Username or email',
      or: 'or',
      'Oops... what a mess.': 'Oops... what a mess.',
      Click: 'Click',
      here: 'here',
      'to try again.': 'to try again.',
      'Show password': 'Show password',
      'Hide password': 'Hide password',
      'Continue with corporate email': 'Continue with corporate email',
      'Corporate sign in': 'Corporate sign in',
      'Corporate email': 'Corporate email',
      'Using a corporate account?': 'Using a corporate account?',
      'There was a problem finalizing your authentication.': 'There was a problem finalizing your authentication.',
      'Explore Cosmos': 'Explore Cosmos',
      'Please choose a new password to access all Chaos products & services, including V-Ray, Corona, Vantage, and more.':
        'Please choose a new password to access all Chaos products & services, including V-Ray, Corona, Vantage, and more.',
      'Since we are merging your Chaos accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos accounts, please choose a new password to activate or manage your products & services.',
      'Since we are merging your Chaos and Enscape accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos and Enscape accounts, please choose a new password to activate or manage your products & services.',
      'Since we are merging your Chaos and Axyz accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos and Axyz accounts, please choose a new password to activate or manage your products & services.',
      'Since we are merging your Chaos and Corona accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos and Corona accounts, please choose a new password to activate or manage your products & services.',
      'privacy-text-chaos':
        'By continuing, I accept the <privacy>Privacy Policy</privacy>, <terms>Terms of Use</terms> and <eula>EULA</eula>',
      'privacy-text-axyz':
        'By continuing, I accept the <privacy>Privacy Policy</privacy>, <terms>Terms of Use</terms> and <eula>EULA</eula>',
      'privacy-text-corona':
        'By continuing, I accept the <privacy>Privacy Policy</privacy> and <terms>Terms of Use</terms>',
      'privacy-text-enscape': 'By continuing, I accept the terms of <eula>Enscape EULA</eula>',
      'privacy-text-trial':
        'By clicking this button, you confirm you accept the <privacy>Privacy Policy</privacy>, <terms>Terms of Use</terms> and <eula>EULA</eula>',
      'Your account is temporary blocked due to security reasons. Please try again in one minute.':
        'Your account is temporarily unavailable. This may be due to using an incorrect password multiple times or other security reasons.<br/><br/> As the first step, try resetting your password by clicking on the "Forgot your password?" link below.',
      'Sign in to get your trial': 'Sign in to get your trial',
      'Create account to get your trial': 'Create account to get your trial',
      'We could not sign you in. If the problem persists, please':
        'We could not sign you in. If the problem persists, please',
      'Your user has been disabled in our system.': 'Your user has been disabled in our system.',
      'Sign in to complete your purchase': 'Sign in to complete your purchase',
      'Sign in to activate the Corona trial on your account': 'Sign in to activate the Corona trial on your account',
      'Create account to complete your purchase': 'Create account to complete your purchase',
      'Sign in complete.': 'Sign in complete.',
      'You can now start using all Chaos products licensed to you':
        'You can now start using all Chaos products licensed to you',
      'You may now close this window.': 'You may now close this window.',
      'An error has occurred during authentication. Try to sign in again.':
        'An error has occurred during authentication. Try to sign in again.',
      'Still having problems signing in?': 'Still having problems signing in?',
      'Visit our Help Center.': 'Visit our Help Center.',
      'Sign in with a different account?': 'Sign in with a different account?',
      'Go to chaos.com': 'Go to chaos.com',
      'You are now signed out of all Chaos products licensed to you':
        'You are now signed out of all Chaos products licensed to you',
      'Sign out complete.': 'Sign out complete.',
      'Work email': 'Work email',
      'You can organize and share your Enscape uploads, create Panorama Tours and manage licenses by accessing your account.':
        'You can organize and share your Enscape uploads, create Panorama Tours and manage licenses by accessing your account.',
      'Go to my.enscape3d.com': 'Go to my.enscape3d.com',
      'Authentication complete': 'Authentication complete',
      'You may now close this window and proceed to the Enscape Application':
        'You may now close this window and proceed to the Enscape Application',
      'You are now signed in as': 'You are now signed in as',
      'You may now close this window': 'You may now close this window',
      'and proceed to the Enscape Application': 'and proceed to the Enscape Application',
      'Enter your email.': 'Enter your email.',
      'You will use it to access your trial.': 'You will use it to access your trial.',
      'Work or educational email': 'Work or educational email',
      'Create a Chaos account to start your free trial.': 'Create a Chaos account to start your free trial.',
      'Your Chaos account has been created successfully.': 'Your Chaos account has been created successfully.',
      'Activation is required.': 'Activation is required.',
      'Check your email': 'Check your email',
      "We've sent an email to": "We've sent an email to",
      'with a link to activate your account and access your trial.':
        'with a link to activate your account and access your trial.',
      resend: "If you don't see the email, check your spam folder or try <send>sending it again</send>.",
      Continue: 'Continue',
      'Your password should contain': 'Your password should contain',
      'Change email': 'Change email',
      'Please enter a valid email.': 'Please enter a valid email.',
      'Confirm your email.': 'Confirm your email.',
      'Start your trial': 'Start your trial',
    },
  },
  es: {
    translation: {
      'Discourse Sign In': 'Discurso Iniciar sesión',
      'Oops...': 'Oops...',
      'Continue with Google': 'Iniciar sesión con Google',
      'Sign in with Trimble': 'Iniciar sesión con Trimble',
      'Continue with Trimble': 'Continuar con Trimble',
      'Sign in with Facebook': 'Iniciar sesión con Facebook',
      'Simple, fast, smart cloud rendering': 'Renderizado en la nube, inteligente, simple, rápido',
      'Start with 20 free credits': 'Comience con 20 créditos gratuitos',
      'A global computer graphics event for 3D artists and developers':
        'Un evento mundial de gráficos por computadora para artistas y desarrolladores 3D',
      'Get your ticket': 'Obtenga su ticket',
      'Epic CG event returns to Sofia': 'El épico evento CG regresa a Sofia',
      'Early Bird tickets out now': 'Entradas anticipadas disponibles ahora',
      'Get tickets': 'Obtener tickets',
      'New user?': '¿Nuevo usuario?',
      'Create Account': 'Cree una cuenta',
      'Sign in': 'Registrarse',
      'Please wait...': 'Por favor, espere...',
      Password: 'Contraseña',
      'Forgot your password?': '¿Olvido su contraseña?',
      'Resend activation link': 'Reenviar link de activación',
      'A new activation link has been sent to': 'Se ha enviado un nuevo link de activación a',
      'Please use it to activate your account.': 'Por favor, úselo para activar su cuenta.',
      'Your account is active': 'Su cuenta está activada',
      'Welcome to Chaos!': '¡Bienvenido a Chaos!',
      'Welcome to Corona!': '¡Bienvenido a Corona!',
      'Delete account': 'Eliminar cuenta',
      'Are you sure you want to delete all data associated with your account?':
        '¿Está seguro que desea eliminar todos sus datos asociados con su cuenta?',
      'Delete all data': 'Eliminar todos los datos',
      'Thank you!': '¡Gracias!',
      'All data was successfully deleted.': 'Todos los datos se eliminaron correctamente.',
      'We apologize for any inconvenience.': 'Una disculpa por cualquier inconveniente.',
      'Something went wrong.': 'Algo salio mal.',
      'Having troubles?': '¿Tiene problemas?',
      'If you experience problems, contact our': 'Si está teniendo problemas, comuníquese con nuestro',
      'Customer Service.': 'Servicio al cliente.',
      'Already have an account?': '¿Ya tiene una cuenta?',
      'Back to profile': 'Regresar al perfil',
      'Change your password': 'Cambiar su contraseña',
      'Old password': 'Contraseña anterior',
      'New password': 'Contraseña nueva',
      'Change Password': 'Cambiar contraseña',
      'Password successfully updated. Redirecting you to login page...':
        'Contraseña actualizada correctamente. Redirigiendo a la página de inicio de sesión...',
      "Enter your email and we'll": 'Ingrese su email y nosotros',
      'send you instructions to reset your password': 'enviaremos instrucciones para restablecer su contraseña',
      Email: 'Email',
      Recover: 'Recuperar',
      "with a link to reset your password. If you don't see the email, please check your spam folder.":
        'Con un link para restablecer su contraseña. Si no ve el email, por favor, verifique su carpeta de correo no deseado.',
      'Back to login': 'Volver a iniciar sesión',
      'LOADING...': 'Cargando...',
      'Request a new one': 'Solicitar uno nuevo',
      'Set your new password': 'Establezca su nueva contraseña',
      '(12 or more characters)': '(12 o más caracteres)',
      Update: 'Actualizar',
      'Set your password': 'Establezca su contraseña',
      'SET PASSWORD': 'CONFIGURAR CONTRASEÑA',
      'Leave page?\nChanges that you made may not be saved.':
        '¿Salir de la página?\nEs posible que los cambios que haya realizado no se guarden.',
      Logout: 'Cerrar sesión',
      'Edit profile': 'Editar perfil',
      'Your profile was updated!': '¡Su perfil ha sido actualizado!',
      'Go back to': 'Volver a',
      'chaos website': 'Sitio web de Chaos',
      'corona website': 'Corona website',
      'enscape website': 'Enscape website',
      'Loading your account...': 'Cargando su cuenta...',
      'Loading your details...': 'Cargando sus detalles...',
      'First name': 'Nombre',
      'Last name': 'Appelido',
      '(Optional)': '(opcional)',
      'Customer Number': 'Número de cliente',
      'Save changes': 'Guardar los cambios',
      'Change password': 'Cambiar contraseña',
      'Reload captcha': 'Recargar captcha',
      'Please enter the numbers on the image': 'Por favor, ingrese los números en la imagen',
      Back: 'Atrás',
      'Almost there': 'Ya casi',
      'Create account': 'Crear cuenta',
      "Let's be friends. It's free.": 'Seamos amigos. Es gratis.',
      'privacy-text-chaos':
        'Al continuar, acepto la <privacy>Política de privacidad</privacy>, <terms>Los términos de uso</terms> y <eula>EULA</eula>',
      'privacy-text-corona':
        'Al continuar, acepto la <privacy>Política de privacidad</privacy> y <terms>Los términos de uso</terms>',
      'privacy-text-enscape': 'By continuing, I accept the terms of <eula>Enscape EULA</eula>',
      'privacy-text-trial':
        'Al hacer click en este botón, confirmas que aceptas la <privacy>Política de Privacidad</privacy>, <terms>los Términos de Uso</terms> y el <eula>EULA</eula>.',
      Next: 'Siguiente',
      'Complete your profile': 'Complete su perfil',
      'Country or Region': 'País o Región',
      newsletterSubscription:
        'Quiero recibir ocasionalmente noticias sobre ofertas exclusivas, productos, eventos, y webinarios.',
      'Your account has been created.': 'Su cuenta ha sido creada.',
      "with a link to activate your account. If you don't see the email, please check your spam folder or try":
        'Con un link para activar su cuenta, Si no ve el email, por favor, verifique su carpeta de correo no deseado',
      sending: 'enviando',
      'the email again.': 'el email nuevamente.',
      "We've resent the activation code successfully. If you still don't see the email, please":
        'Hemos reenviado el código de activación correctamente. Si aún no ve el email, por favor',
      contact: 'contacte',
      'our support team.': 'nuestro equipo de soporte.',
      'Link existing account': 'Vincular cuenta existente',
      'Account with this email already exists.': 'La cuenta con este email ya existe.',
      Change: 'Cambiar',
      'Enter your Chaos password to link it to your': 'Ingrese su contraseña de Chaos para vincularla a su',
      'account.': 'cuenta.',
      'Chaos Password': 'Contraseña de Chaos',
      'LINK ACCOUNTS': 'VINCULAR CUENTAS',
      'We will use your Google details to create your Chaos account':
        'Usaremos sus datos de Google para crear su cuenta de Chaos',
      'We will use your Facebook details to create your Chaos account':
        'Usaremos sus datos de Facebook para crear su cuenta de Chaos',
      'We will use your Trimble details to create your Chaos account':
        'Usaremos sus datos de Trimble para crear su cuenta de Chaos',
      Country: 'País',
      'Link an existing account': 'Vincular a una cuenta existente',
      'CREATE ACCOUNT': 'CREAR CUENTA',
      'Account created': 'Cuenta creada',
      'Your Chaos account is now active.': 'Su cuenta de Chaos está ahora activada.',
      OK: 'OK',
      'A Chaos account has been created and linked to your': 'Una cuenta de Chaos ha sido creada y vinculada a su',
      'Trimble Account.': 'Cuenta de Trimble.',
      'Fast interactive rendering. New, smart functionality.':
        'Renderizado interactivo rápido. Nueva funcionalidad inteligente.',
      'Sign in with your Trimble account to start using V-Ray for SketchUp Studio':
        'Inicie sesión con su cuenta de Trimble para comenzar a usar V-Ray para SketchUp Studio',
      'Loading...': 'Cargando...',
      '404 – PAGE NOT FOUND': '404 – PAGE NOT FOUND',
      'Please enter your username or email address.': 'Por favor, introduzca su nombre de usuario o email.',
      'Invalid activation link.': 'Link de activación no válido.',
      'Invalid delete account link.': 'Link de eliminación de cuenta no válido.',
      'Please enter your email address.': 'Por favor, introduzca su email.',
      'Please enter valid email address.': 'Por favor, introduzca un email válido.',
      'Invalid password.': 'Contraseña no válida.',
      'This email address is already connected to an account.':
        'Esta dirección de email ya está conectada a una cuenta.',
      instead: 'instead',
      'Please enter your password.': 'Por favor, introduzca su contraseña.',
      'Your password must be at least 8 characters long.': 'Su contraseña debe tener al menos 8 caracteres.',
      'Your password must be at most 64 characters long.': 'Su contraseña debe tener como máximo 64 caracteres.',
      'Both lower and upper case letters': 'Tanto letras minúsculas como mayúsculas',
      'At least one number': 'Al menos un número',
      'At least one symbol': 'Al menos un símbolo',
      'At least 12 characters': 'Al menos 12 caracteres',
      'Please enter your first name.': 'Por favor, introduzca su nombre.',
      'Your first name cannot be more than 72 characters long.': 'Su nombre no puede tener más de 72 caracteres.',
      'Please enter your last name.': 'Por favor, introduzca su apellido.',
      'Your last name cannot be more than 53 characters long.': 'Su apellido no puede tener más de 53 caracteres.',
      'Please enter your country.': 'Por favor, introduzca su país.',
      'Non-Latin letters': 'Letras no latinas',
      'No Internet connection. Please check the network cables, modem and router or reconnect to Wi-Fi.':
        'Sin conexión a internet. Compruebe los cables de red, el modem y el router o vuelva a conectarse al Wi-Fi.',
      'We are having technical difficulties. Please try later.':
        'Estamos teniendo dificultades técnicas. Por favor, intente más tarde.',
      'An unexpected error occurred.': 'Ocurrió un error inesperado.',
      'Username or email': 'Email o nombre de usuario',
      or: 'o',
      'Oops... what a mess.': 'Oops... que desastre.',
      Click: 'Click',
      here: 'aquí',
      'to try again.': 'para volver a intentarlo.',
      'Show password': 'Mostrar contraseña',
      'Hide password': 'Ocultar contraseña',
      'Continue with corporate email': 'Continuar con cuenta corporativa',
      'Corporate sign in': 'Iniciar sesión corporativa',
      'Corporate email': 'Email corporativo',
      'Using a corporate account?': '¿Usando una cuenta corporativa?',
      'There was a problem finalizing your authentication.': 'There was a problem finalizing your authentication.',
      'Explore Cosmos': 'Explore Cosmos',
      'Please choose a new password to access all Chaos products & services, including V-Ray, Corona, Vantage, and more.':
        'Please choose a new password to access all Chaos products & services, including V-Ray, Corona, Vantage, and more.',
      'Since we are merging your Chaos accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos accounts, please choose a new password to activate or manage your products & services.',
      'Since we are merging your Chaos and Enscape accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos and Enscape accounts, please choose a new password to activate or manage your products & services.',
      'Since we are merging your Chaos and Axyz accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos and Axyz accounts, please choose a new password to activate or manage your products & services.',
      'Since we are merging your Chaos and Corona accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos and Corona accounts, please choose a new password to activate or manage your products & services.',
      'Your account is temporary blocked due to security reasons. Please try again in one minute.':
        'Your account is temporarily unavailable. This may be due to using an incorrect password multiple times or other security reasons.<br/><br/> As the first step, try resetting your password by clicking on the "Forgot your password?" link below.',
      'Sign in to get your trial': 'Sign in to get your trial',
      'Sign in to complete your purchase': 'Sign in to complete your purchase',
      'Sign in to activate the Corona trial on your account': 'Sign in to activate the Corona trial on your account',
      'Create account to get your trial': 'Create account to get your trial',
      'We could not sign you in. If the problem persists, please':
        'We could not sign you in. If the problem persists, please',
      'Your user has been disabled in our system.': 'Your user has been disabled in our system.',
      'Sign in complete.': 'Sign in complete.',
      'You can now start using all Chaos products licensed to you':
        'You can now start using all Chaos products licensed to you',
      'You may now close this window.': 'You may now close this window.',
      'An error has occurred during authentication. Try to sign in again.':
        'An error has occurred during authentication. Try to sign in again.',
      'Still having problems signing in?': 'Still having problems signing in?',
      'Visit our Help Center.': 'Visit our Help Center.',
      'Work email': 'Work email',
      'You can organize and share your Enscape uploads, create Panorama Tours and manage licenses by accessing your account.':
        'You can organize and share your Enscape uploads, create Panorama Tours and manage licenses by accessing your account.',
      'Go to my.enscape3d.com': 'Go to my.enscape3d.com',
      'Authentication complete': 'Authentication complete',
      'You may now close this window and proceed to the Enscape Application':
        'You may now close this window and proceed to the Enscape Application',
      'You are now signed in as': 'You are now signed in as',
      'You may now close this window': 'You may now close this window',
      'and proceed to the Enscape Application': 'and proceed to the Enscape Application',
      'Enter your email.': 'Introduce tu email',
      'You will use it to access your trial.': 'Lo usarás para acceder a tu prueba de Chaos.',
      'Work or educational email': 'Email laboral o educativo',
      'Create a Chaos account to start your free trial.': 'Crea una cuenta de Chaos para comenzar tu prueba gratuita.',
      'Your Chaos account has been created successfully.': 'Tu cuenta de Chaos se ha creado con éxito.',
      'Activation is required.': 'Se requiere activación.',
      'Check your email': 'Revisa tu email',
      "We've sent an email to": 'Hemos enviado un email a',
      'with a link to activate your account and access your trial.':
        'con un enlace para activar tu cuenta y acceder a tu prueba.',
      resend: 'Si no ves el email, revisa tu carpeta de spam o intenta <send>enviarlo nuevamente</send>.',
      Continue: 'Continuar',
      'Your password should contain': 'Tu contraseña debe contener',
      'Change email': 'Cambiar email',
      'Please enter a valid email.': 'Por favor introduce un email válido.',
      'Confirm your email.': 'Confirma tu email.',
      'Start your trial': 'Comienza tu prueba',
    },
  },
  pt: {
    translation: {
      'Discourse Sign In': 'Entrar',
      'Oops...': 'Oops...',
      'Continue with Google': 'Entrar com Google',
      'Sign in with Trimble': 'Entrar com Trimble',
      'Continue with Trimble': 'Continuar com Trimble',
      'Sign in with Facebook': 'Entrar com Facebook',
      'Simple, fast, smart cloud rendering': 'Renderização na nuvem simples, rápida e inteligente.',
      'Start with 20 free credits': 'Comece com 20 créditos grátis',
      'A global computer graphics event for 3D artists and developers':
        'Um evento global de computação gráfica para artistas 3D e desenvolvedores',
      'Get your ticket': 'Garanta seu ingresso',
      'Epic CG event returns to Sofia': 'Evento de CG épico retorna a Sofia',
      'Early Bird tickets out now': 'Pré-venda de ingressos já disponível',
      'Get tickets': 'Comprar ingressos',
      'New user?': 'Usuário novo?',
      'Create Account': 'Criar Conta',
      'Sign in': 'Entrar',
      'Please wait...': 'Aguarde...',
      Password: 'Senha',
      'Forgot your password?': 'Esqueceu sua senha?',
      'Resend activation link': 'Enviar link de ativação novamente',
      'A new activation link has been sent to': 'Um novo link de ativação foi enviado para',
      'Please use it to activate your account.': 'Por favor, use-o para ativar sua conta.',
      'Your account is active': 'Sua conta está ativa',
      'Welcome to Chaos!': 'Bem-vindo à Chaos!',
      'Welcome to Corona!': 'Bem-vindo à Corona!',
      'Delete account': 'Apagar conta',
      'Are you sure you want to delete all data associated with your account?':
        'Você tem certeza de que deseja apagar todos os dados associados à sua conta?',
      'Delete all data': 'Apagar todos os dados',
      'Thank you!': 'Obrigado!',
      'All data was successfully deleted.': 'Todos os dados foram apagados com sucesso.',
      'We apologize for any inconvenience.': 'Pedimos desculpas por qualquer inconveniente.',
      'Something went wrong.': 'Algo deu errado.',
      'Having troubles?': 'Tendo problemas?',
      'If you experience problems, contact our': 'Se tiver algum problema, entre em contato com nosso',
      'Customer Service.': 'Atendimento ao cliente.',
      'Already have an account?': 'Já tem uma conta?',
      'Back to profile': 'Voltar para o perfil',
      'Change your password': 'Mudar sua senha',
      'Old password': 'Senha antiga',
      'New password': 'Nova senha',
      'Change Password': 'Mudar senha',
      'Password successfully updated. Redirecting you to login page...':
        'Senha alterada com sucesso. Redirecionando você para a página de login...',
      "Enter your email and we'll": 'Digite seu e-mail e nós iremos',
      'send you instructions to reset your password': 'enviar instruções para resetar sua senha’',
      Email: 'E-mail',
      Recover: 'Recuperar',
      "with a link to reset your password. If you don't see the email, please check your spam folder.":
        'Com um link para resetar sua senha. Se não encontrar este e-mail, por favor cheque seu lixo eletrônico.',
      'Back to login': 'Voltar para login',
      'LOADING...': 'Carregando...',
      'Request a new one': 'Solicitar nova senha',
      'Set your new password': 'Defina sua nova senha',
      '(12 or more characters)': '(12 ou mais caracteres)',
      Update: 'Atualizar',
      'Set your password': 'Defina sua senha',
      'SET PASSWORD': 'DEFINIR SENHA',
      'Leave page?\nChanges that you made may not be saved.':
        'Sair da página?\nAs alterações feitas podem não ser salvas.',
      Logout: 'Sair',
      'Edit profile': 'Edita perfil',
      'Your profile was updated!': 'Seu perfil foi atualizado!',
      'Go back to': 'Voltar para o',
      'chaos website': 'site da Chaos',
      'corona website': 'Corona website',
      'enscape website': 'Enscape website',
      'Loading your account...': 'Carregando sua conta...',
      'Loading your details...': 'Carregando seus detalhes...',
      'First name': 'Primeiro nome',
      'Last name': 'Sobrenome',
      '(Optional)': '(opcional)',
      'Customer Number': 'Número de Cliente',
      'Save changes': 'Salvar alterações',
      'Change password': 'Mudar senha',
      'Reload captcha': 'Recarregar captcha',
      'Please enter the numbers on the image': 'Por favor, digite os números na imagem',
      Back: 'Voltar',
      'Almost there': 'Quase lá',
      'Create account': 'Criar conta',
      "Let's be friends. It's free.": 'Vamos ser amigos. É grátis.',
      'privacy-text-chaos':
        'Ao continuar, eu aceito a <privacy>Política de Privacidade</privacy>, <terms>Termos de Uso</terms> e <eula>EULA</eula>',
      'privacy-text-corona':
        'Ao continuar, eu aceito a <privacy>Política de Privacidade</privacy> e <terms>Termos de Uso</terms>',
      'privacy-text-enscape': 'By continuing, I accept the terms of <eula>Enscape EULA</eula>',
      'privacy-text-trial':
        'Ao clicar neste botão, você confirma que aceita a <privacy>Política de Privacidade</privacy>, <terms>Termos de Uso</terms> e <eula>EULA</eula>',
      Next: 'Próximo',
      'Complete your profile': 'Complete seu perfil',
      'Country or Region': 'País ou Região',
      newsletterSubscription:
        'Envíenme de forma ocasional, noticias sobre ofertas exclusivas, productos, próximos eventos y webinarios.',
      'Your account has been created.': 'Sua conta foi criada.',
      "with a link to activate your account. If you don't see the email, please check your spam folder or try":
        'Com um link para ativar sua conta. Se não encontrar esse e-mail, por favor verifique seu lixo eletrônico ou tente',
      sending: 'enviar',
      'the email again.': 'o e-mail novamente.',
      "We've resent the activation code successfully. If you still don't see the email, please":
        'Enviamos novamente o código de ativação com sucesso. Se ainda não receber o e-mail, por favor',
      contact: 'contate',
      'our support team.': 'nossa equipe de suporte.',
      'Link existing account': 'Conectar conta existente',
      'Account with this email already exists.': 'Uma conta com este e-mail já existe.',
      Change: 'Mudar',
      'Enter your Chaos password to link it to your': 'Digite sua senha Chaos para conectá-la à sua',
      'account.': 'conta.',
      'Chaos Password': 'Senha Chaos',
      'LINK ACCOUNTS': 'CONECTAR CONTAS',
      'We will use your Google details to create your Chaos account':
        'Usaremos seus detalhes do Goggle para criar sua conta Chaos',
      'We will use your Facebook details to create your Chaos account':
        'Usaremos seus detalhes do Facebook para criar sua conta Chaos',
      'We will use your Trimble details to create your Chaos account':
        'Usaremos seus detalhes da Trimble para criar sua conta Chaos',
      Country: 'País',
      'Link an existing account': 'Conectar a uma conta existente',
      'CREATE ACCOUNT': 'CRIAR CONTA',
      'Account created': 'Conta criada',
      'Your Chaos account is now active.': 'Sua conta Chaos foi ativada.',
      OK: 'OK',
      'A Chaos account has been created and linked to your': 'Uma conta Chaos foi criada e conectada à sua',
      'Trimble Account.': 'conta Trimble.',
      'Fast interactive rendering. New, smart functionality.':
        'Renderização interativa rápida. Nova funcionalidade inteligente.',
      'Sign in with your Trimble account to start using V-Ray for SketchUp Studio':
        'Entre com sua conta Trimble para começar a usar o  V-Ray for SketchUp Studio',
      'Loading...': 'Carregando...',
      '404 – PAGE NOT FOUND': '404 – PÁGINA NÃO ENCONTRADA',
      'Please enter your username or email address.': 'Por favor, entre com seu nome de usuário ou e-mail.',
      'Invalid activation link.': 'Link de Ativação inválido.',
      'Invalid delete account link.': 'Link para apagar conta inválido.',
      'Please enter your email address.': 'Por favor, digite seu e-mail.',
      'Please enter valid email address.': 'Por favor, digite um endereço de e-mail válido.',
      'Invalid password.': 'Senha inválida.',
      'This email address is already connected to an account.': 'Este e-mail já está conectado a uma conta.',
      instead: 'instead',
      'Please enter your password.': 'Por favor, digite sua senha.',
      'Your password must be at least 8 characters long.': 'Sua senha precisa ter pelo menos 8 caracteres.',
      'Your password must be at most 64 characters long.': 'Sua senha pode ter no máximo 64 caracteres.',
      'Both lower and upper case letters': 'Maiúsculas e minúsculas',
      'At least one number': 'Pelo menos um número',
      'At least one symbol': 'Pelo menos um símbolo',
      'At least 12 characters': 'Pelo menos 12 caracteres',
      'Please enter your first name.': 'Por favor, digite seu nome.',
      'Your first name cannot be more than 72 characters long.': 'Seu nome não pode ter mais do que 72 caracteres.',
      'Please enter your last name.': 'Por favor, digite seu sobrenome.',
      'Your last name cannot be more than 53 characters long.': 'Seu sobrenome não pode ter mais de 53 caracteres.',
      'Please enter your country.': 'Informe seu país.',
      'Non-Latin letters': 'Letras não-latinas',
      'No Internet connection. Please check the network cables, modem and router or reconnect to Wi-Fi.':
        'Sem conexão com a internet. Por favor, verifique os cabos de rede, modem e roteador ou reconecte o Wi-Fi.',
      'We are having technical difficulties. Please try later.':
        'Estamos passando por dificuldades técnicas. Por favor, tente novamente mais tarde.',
      'An unexpected error occurred.': 'Ocorreu um erro inesperado.',
      'Username or email': 'E-mail ou Nome de Usuário',
      or: 'ou',
      'Oops... what a mess.': 'Oops... que bagunça.',
      Click: 'Clique',
      here: 'aqui',
      'to try again.': 'para tentar novamente.',
      'Show password': 'Mostrar senha',
      'Hide password': 'Esconder senha',
      'Continue with corporate email': 'Continuar com conta corporativa',
      'Corporate sign in': 'Login corporativo',
      'Corporate email': 'E-mail corporativo',
      'Using a corporate account?': 'Usando uma conta corporativa?',
      'There was a problem finalizing your authentication.': 'There was a problem finalizing your authentication.',
      'Explore Cosmos': 'Explore Cosmos',
      'Please choose a new password to access all Chaos products & services, including V-Ray, Corona, Vantage, and more.':
        'Please choose a new password to access all Chaos products & services, including V-Ray, Corona, Vantage, and more.',
      'Since we are merging your Chaos accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos accounts, please choose a new password to activate or manage your products & services.',
      'Since we are merging your Chaos and Enscape accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos and Enscape accounts, please choose a new password to activate or manage your products & services.',
      'Since we are merging your Chaos and Axyz accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos and Axyz accounts, please choose a new password to activate or manage your products & services.',
      'Since we are merging your Chaos and Corona accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos and Corona accounts, please choose a new password to activate or manage your products & services.',
      'Your account is temporary blocked due to security reasons. Please try again in one minute.':
        'Your account is temporarily unavailable. This may be due to using an incorrect password multiple times or other security reasons.<br/><br/> As the first step, try resetting your password by clicking on the "Forgot your password?" link below.',
      'Sign in to get your trial': 'Sign in to get your trial',
      'Sign in to complete your purchase': 'Sign in to complete your purchase',
      'Sign in to activate the Corona trial on your account': 'Sign in to activate the Corona trial on your account',
      'Create account to get your trial': 'Create account to get your trial',
      'We could not sign you in. If the problem persists, please':
        'We could not sign you in. If the problem persists, please',
      'Your user has been disabled in our system.': 'Your user has been disabled in our system.',
      'Sign in complete.': 'Sign in complete.',
      'You can now start using all Chaos products licensed to you':
        'You can now start using all Chaos products licensed to you',
      'You may now close this window.': 'You may now close this window.',
      'An error has occurred during authentication. Try to sign in again.':
        'An error has occurred during authentication. Try to sign in again.',
      'Still having problems signing in?': 'Still having problems signing in?',
      'Visit our Help Center.': 'Visit our Help Center.',
      'Work email': 'Work email',
      'You can organize and share your Enscape uploads, create Panorama Tours and manage licenses by accessing your account.':
        'You can organize and share your Enscape uploads, create Panorama Tours and manage licenses by accessing your account.',
      'Go to my.enscape3d.com': 'Go to my.enscape3d.com',
      'Authentication complete': 'Authentication complete',
      'You may now close this window and proceed to the Enscape Application':
        'You may now close this window and proceed to the Enscape Application',
      'You are now signed in as': 'You are now signed in as',
      'You may now close this window': 'You may now close this window',
      'and proceed to the Enscape Application': 'and proceed to the Enscape Application',
      'Enter your email.': 'Digite seu e-mail',
      'You will use it to access your trial.': 'Você usará para acessar seu período de teste do Chaos.',
      'Work or educational email': 'E-mail de trabalho ou educacional',
      'Create a Chaos account to start your free trial.':
        'Crie uma conta Chaos para começar seu período de teste gratuito.',
      'Your Chaos account has been created successfully.': 'Sua conta Chaos foi criada com sucesso.',
      'Activation is required.': 'Ativação é necessária.',
      'Check your email': 'Verifique seu e-mail',
      "We've sent an email to": 'Enviamos um e-mail para',
      'with a link to activate your account and access your trial.':
        'com um link para ativar sua conta e acessar sua versão de teste.',
      resend: 'Se você não encontrar o e-mail, verifique sua pasta de spam ou tente <send>enviar novamente</send>.',
      Continue: 'Continuar',
      'Your password should contain': 'Sua senha deve conter',
      'Change email': 'Alterar e-mail',
      'Please enter a valid email.': 'Favor inserir um e-mail válido.',
      'Confirm your email.': 'Confirme seu e-mail.',
      'Start your trial': 'Inicie seu trial',
    },
  },
  cn: {
    translation: {
      'Discourse Sign In': 'Discourse 登录',
      'Oops...': '糟糕...',
      'Continue with Google': '使用 Google 账号登录',
      'Sign in with Trimble': '使用 Trimble 账号登录',
      'Continue with Trimble': '使用 Trimble 登录',
      'Sign in with Facebook': '使用 Facebook 账号登录',
      'Simple, fast, smart cloud rendering': '简单, 快速, 智能的云渲染',
      'Start with 20 free credits': '20点免费试用点数',
      'A global computer graphics event for 3D artists and developers': '面向全球3D艺术家和开发者的图形学大会',
      'Get your ticket': '获取门票',
      'Epic CG event returns to Sofia': 'CG 盛会回归索菲亚',
      'Early Bird tickets out now': '现可购买早鸟票',
      'Get tickets': '获取门票',
      'New user?': '新用户？',
      'Create Account': '创建新账号',
      'Sign in': '登录',
      'Please wait...': '请稍等',
      Password: '密码',
      'Forgot your password?': '忘记密码？',
      'Resend activation link': '重新发送激活链接',
      'A new activation link has been sent to': '一个新的链接已经被发送到',
      'Please use it to activate your account.': '请使用它来激活您的账号。',
      'Your account is active': '您的账号已经激活',
      'Welcome to Chaos!': '欢迎来到 Chaos!',
      'Welcome to Corona!': '欢迎来到 Corona!',
      'Delete account': '删除账号',
      'Are you sure you want to delete all data associated with your account?': '您确认您想要删除所有账号相关信息吗？',
      'Delete all data': '删除全部信息',
      'Thank you!': '感谢！',
      'All data was successfully deleted.': '所有信息已经成功删除。',
      'We apologize for any inconvenience.': '如有不便，敬请谅解。',
      'Something went wrong.': '发生错误。',
      'Having troubles?': '遇到了问题？',
      'If you experience problems, contact our': '如果您有疑问，请联系我们的',
      'Customer Service.': '客户服务。',
      'Already have an account?': '已经有账号了？',
      'Back to profile': '返回个人信息',
      'Change your password': '修改您的密码',
      'Old password': '旧密码',
      'New password': '新密码',
      'Change Password': '修改密码',
      'Password successfully updated. Redirecting you to login page...': '密码成功更新。重新定向到登录页面...',
      "Enter your email and we'll": '输入您的电子邮箱，我们会',
      'send you instructions to reset your password': '向您发送重置密码的说明',
      Email: '电子邮件',
      Recover: '复原',
      "with a link to reset your password. If you don't see the email, please check your spam folder.":
        '含有重置密码的链接。如果您没有收到这封邮件，请检查您的垃圾邮箱。',
      'Back to login': '返回登录',
      'LOADING...': '加载中...',
      'Request a new one': '重新发送',
      'Set your new password': '设置新密码',
      '(12 or more characters)': '(12 或更多字符)',
      Update: '更新',
      'Set your password': '设置密码',
      'SET PASSWORD': '设置密码',
      'Leave page? Changes that you made may not be saved.': '离开页面？您的修改可能不会被保存。',
      Logout: '登出',
      'Edit profile': '编辑个人信息',
      'Your profile was updated!': '您的个人信息已经更新！',
      'Go back to': '返回',
      'chaos website': 'Chaos 网站',
      'corona website': 'Corona website',
      'enscape website': 'Enscape website',
      'Loading your account...': '读取您的账户',
      'Loading your details...': '读取详细信息',
      'First name': '姓',
      'Last name': '名',
      '(Optional)': '(可选)',
      'Customer Number': '用户编号',
      'Save changes': '保存修改',
      'Change password': '修改密码',
      'Reload captcha': '重新加载验证码',
      'Please enter the numbers on the image': '请输入图中的数字',
      Back: '返回',
      'Almost there': '就快完成',
      'Create account': '创建账号',
      "Let's be friends. It's free.": '欢迎注册免费账号。',
      'privacy-text-chaos': '要继续，我接受 <privacy>隐私政策</privacy>，<terms>使用条款</terms> 和 <eula>EULA</eula>',
      'privacy-text-corona': '要继续，我接受 <privacy>隐私政策</privacy> 和 <terms>使用条款</terms>',
      'privacy-text-enscape': 'By continuing, I accept the terms of <eula>Enscape EULA</eula>',
      'privacy-text-trial':
        '点击此按钮即表示您同意遵守<privacy>隐私政策</privacy>、<terms>使用条款</terms><eula>及最终用户许可协议(EULA)</eula>。',
      Next: '下一步',
      'Complete your profile': '补充完整您的信息',
      'Country or Region': '国家或地区',
      newsletterSubscription: '偶尔向我推送独家优惠，产品以及即将举行的活动和网络研讨会相关的信息',
      'Your account has been created.': '已经成功创建您的账号。',
      "with a link to activate your account. If you don't see the email, please check your spam folder or try":
        '含有账号的激活链接。如果您没有收到这封邮件，请检查您的垃圾邮箱或重试。',
      sending: '发送',
      'the email again.': '重新发送邮件。',
      "We've resent the activation code successfully. If you still don't see the email, please":
        '我们已经重新发送了激活码。如果您仍然没有收到邮件，请',
      contact: '联系',
      'our support team.': '我们的技术支持团队。',
      'Link existing account': '关联已有账号',
      'Account with this email already exists.': '这个邮箱已经使用。',
      Change: '修改',
      'Enter your Chaos password to link it to your': '输入您的 Chaos 密码，链接到您的',
      'account.': '账号。',
      'Chaos Password': 'Chaos 密码',
      'LINK ACCOUNTS': '关联账号',
      'We will use your Google details to create your Chaos account':
        '我们会使用您的 Google 账号信息创建您的 Chaos 账号',
      'We will use your Facebook details to create your Chaos account':
        '我们会使用您的 Facebook 账号信息创建您的 Chaos 账号',
      'We will use your Trimble details to create your Chaos account':
        '我们会使用您的 Trimble 账号信息创建您的 Chaos 账号',
      Country: '国家或地区',
      'Link an existing account': '关联到已有账号',
      'CREATE ACCOUNT': '创建账号',
      'Account created': '账号已创建',
      'Your Chaos account is now active.': '您的 Chaos 账号已经激活。',
      OK: '好',
      'A Chaos account has been created and linked to your': '已经创建 Chaos 账号并关联到您的',
      'Trimble Account.': 'Trimble 账号。',
      'Fast interactive rendering. New, smart functionality.': '快速的交互式渲染。新增智能功能。',
      'Sign in with your Trimble account to start using V-Ray for SketchUp Studio':
        '登录您的 Trimble 账号，开始使用 V-Ray for SketchUp Studio',
      'Loading...': '加载中...',
      '404 – PAGE NOT FOUND': '404 – 无法找到该页面',
      'Please enter your username or email address.': '请输入您的用户名和邮箱地址。',
      'Invalid activation link.': '无效的激活链接。',
      'Invalid delete account link.': '无效的删除账户链接。',
      'Please enter your email address.': '请输入您的电子邮箱。',
      'Please enter valid email address.': '请输入有效的电子邮箱。',
      'Invalid password.': '无效的密码。',
      'This email address is already connected to an account.': '该电子邮箱地址已关联至现有账户。',
      instead: 'instead',
      'Please enter your password.': '请输入您的密码。',
      'Your password must be at least 8 characters long.': '您的密码长度至少是8个字符。',
      'Your password must be at most 64 characters long.': '您的密码长度不能超过64个字符。',
      'Both lower and upper case letters': '至少包含一个大写字母和一个小写字母',
      'At least one number': '至少包含一个数字',
      'At least one symbol': '至少包含一个符号',
      'At least 12 characters': '长度不少于12个字符',
      'Please enter your first name.': '请输入您的姓。',
      'Your first name cannot be more than 72 characters long.': '您的姓长度不能超过72个字符',
      'Please enter your last name.': '请输入您的名。',
      'Your last name cannot be more than 53 characters long.': '您的名长度不能超过53个字符。',
      'Please enter your country.': '请输入您的国家或地区。',
      'Non-Latin letters': '非拉丁字符',
      'No Internet connection. Please check the network cables, modem and router or reconnect to Wi-Fi.':
        '没有网络连接。请检查您的网线路由器和端口以及无线网络连接。',
      'We are having technical difficulties. Please try later.': '我们遇到了技术问题，请重试。',
      'An unexpected error occurred.': '出现未知错误。',
      'Username or email': '邮箱或用户名',
      or: '或者',
      'Oops... what a mess.': '糟糕，出错了',
      Click: '点击',
      here: '这里',
      'to try again.': '请重试。',
      'Show password': '显示密码',
      'Hide password': '隐藏密码',
      'Continue with corporate email': '使用企业账户登录',
      'Corporate sign in': '企业登录',
      'Corporate email': '企业邮箱',
      'Using a corporate account?': '是否使用企业账户登录？',
      'There was a problem finalizing your authentication.': 'There was a problem finalizing your authentication.',
      'Explore Cosmos': 'Explore Cosmos',
      'Please choose a new password to access all Chaos products & services, including V-Ray, Corona, Vantage, and more.':
        'Please choose a new password to access all Chaos products & services, including V-Ray, Corona, Vantage, and more.',
      'Since we are merging your Chaos accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos accounts, please choose a new password to activate or manage your products & services.',
      'Since we are merging your Chaos and Enscape accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos and Enscape accounts, please choose a new password to activate or manage your products & services.',
      'Since we are merging your Chaos and Axyz accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos and Axyz accounts, please choose a new password to activate or manage your products & services.',
      'Since we are merging your Chaos and Corona accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos and Corona accounts, please choose a new password to activate or manage your products & services.',
      'Your account is temporary blocked due to security reasons. Please try again in one minute.':
        'Your account is temporarily unavailable. This may be due to using an incorrect password multiple times or other security reasons.<br/><br/> As the first step, try resetting your password by clicking on the "Forgot your password?" link below.',
      'Sign in to get your trial': 'Sign in to get your trial',
      'Sign in to complete your purchase': 'Sign in to complete your purchase',
      'Sign in to activate the Corona trial on your account': 'Sign in to activate the Corona trial on your account',
      'Create account to get your trial': 'Create account to get your trial',
      'We could not sign you in. If the problem persists, please':
        'We could not sign you in. If the problem persists, please',
      'Your user has been disabled in our system.': 'Your user has been disabled in our system.',
      'Sign in complete.': 'Sign in complete.',
      'You can now start using all Chaos products licensed to you':
        'You can now start using all Chaos products licensed to you',
      'You may now close this window.': 'You may now close this window.',
      'An error has occurred during authentication. Try to sign in again.':
        'An error has occurred during authentication. Try to sign in again.',
      'Still having problems signing in?': 'Still having problems signing in?',
      'Visit our Help Center.': 'Visit our Help Center.',
      'Work email': 'Work email',
      'You can organize and share your Enscape uploads, create Panorama Tours and manage licenses by accessing your account.':
        'You can organize and share your Enscape uploads, create Panorama Tours and manage licenses by accessing your account.',
      'Go to my.enscape3d.com': 'Go to my.enscape3d.com',
      'Authentication complete': 'Authentication complete',
      'You may now close this window and proceed to the Enscape Application':
        'You may now close this window and proceed to the Enscape Application',
      'You are now signed in as': 'You are now signed in as',
      'You may now close this window': 'You may now close this window',
      'and proceed to the Enscape Application': 'and proceed to the Enscape Application',
      'Enter your email.': '输入您的电子邮箱地址',
      'You will use it to access your trial.': '您将使用它来访问您的 Chaos 试用版。',
      'Work or educational email': '工作或教育邮箱',
      'Create a Chaos account to start your free trial.': '创建 Chaos 账号，开始免费试用。',
      'Your Chaos account has been created successfully.': '您的 Chaos 账号已成功创建。',
      'Activation is required.': '需要激活',
      'Check your email': '请检查您的电子邮箱',
      "We've sent an email to": '我们已经向',
      'with a link to activate your account and access your trial.':
        '发生了一封电子邮件，其中包含激活账号和访问试用版的链接。',
      resend: '若您未找到该电子邮件，请检查您的垃圾邮件文件夹或尝试<send>再次发送</send>。',
      Continue: '继续',
      'Your password should contain': '您的密码应包含',
      'Change email': '更改电子邮箱',
      'Please enter a valid email.': '请输入有效电子邮箱地址。',
      'Confirm your email.': '确认您的电子邮箱。',
      'Start your trial': '开始试用',
    },
  },
  kr: {
    translation: {
      'Discourse Sign In': 'Discourse에 로그인하기',
      'Oops...': 'Oops...',
      'Continue with Google': 'Google로 로그인하기',
      'Sign in with Trimble': 'Trimble로 로그인하기',
      'Continue with Trimble': 'Trimble로 계속하기',
      'Sign in with Facebook': 'Facebook으로 로그인하기',
      'Simple, fast, smart cloud rendering': '간편하고, 빠르고, 스마트한 클라우드 렌더링',
      'Start with 20 free credits': '무료 20크레딧으로 테스트 시작하기',
      'A global computer graphics event for 3D artists and developers':
        '3D 아티스트와 개발자들을 위한 글로벌 컴퓨터 그래픽 이벤트',
      'Get your ticket': '티켓 구매하기',
      'Epic CG event returns to Sofia': '새로운 신화를 쓰고 있는 CG이벤트가  소피아로 다시 돌아왔습니다.',
      'Early Bird tickets out now': '조기할인 티켓 구매 가능',
      'Get tickets': '티켓구매하기',
      'New user?': '새로운 유저입니까?',
      'Create Account': '계정 생성하기',
      'Sign in': '로그인 하기',
      'Please wait...': '잠시만 기다려주십시오...',
      Password: '비밀번호',
      'Forgot your password?': '비밀번호를 잊어버리셨나요?',
      'Resend activation link': 'Activation link 다시 보내기',
      'A new activation link has been sent to': '새로운 Activation link가 다시 전달되었습니다',
      'Please use it to activate your account.': '계정을 활성화하는 데 사용하십시오.',
      'Your account is active': '계정이 활성화되었습니다',
      'Welcome to Chaos!': 'Chaos에 참여하심을 환영합니다!',
      'Welcome to Corona!': 'Corona에 참여하심을 환영합니다!',
      'Delete account': '계정 삭제하기',
      'Are you sure you want to delete all data associated with your account?':
        '계정과 관련된 모든 데이터를 삭제 하시겠습니까?',
      'Delete all data': '모든 데이터 삭제하기',
      'Thank you!': '감사합니다!',
      'All data was successfully deleted.': '모든 데이터가 성공적으로 삭제되었습니다.',
      'We apologize for any inconvenience.': '불편을 드려서 죄송합니다',
      'Something went wrong.': '잘못진행되었습니다.',
      'Having troubles?': '문제가 있으십니까?',
      'If you experience problems, contact our': '문제가 있다면 연락주십시오',
      'Customer Service.': '고객 서비스',
      'Already have an account?': '이미 계정이 있으십니까?',
      'Back to profile': '프로필로 다시 돌아가기',
      'Change your password': '비밀번호 변경하기',
      'Old password': '구 비밀번호',
      'New password': '새로운 비밀번호',
      'Change Password': '비밀번호 변경하기',
      'Password successfully updated. Redirecting you to login page...':
        '비밀번호가 성공적으로 업데이트되었습니다. 로그인 페이지로 리디렉션 중...',
      "Enter your email and we'll": '당신의 이메일 주소를 입력하여 주십시오.',
      '비밀번호 재설정을 위한 안내서를 전달드리겠습니다': '',
      Email: '이메일',
      Recover: '복원하기',
      "with a link to reset your password. If you don't see the email, please check your spam folder.":
        '비밀번호 재설정 링크가 전달되었습니다. 이메일이 보이지 않으면 스팸 폴더를 확인하세요.',
      'Back to login': '로그인 화면으로 다시 돌아가기',
      'LOADING...': 'Loading...',
      'Request a new one': '새로운 번호 요청하기',
      'Set your new password': '새로운 비밀번호 설정하기',
      '(12 or more characters)': '(12자리 이상)',
      Update: '업데이트',
      'Set your password': '비밀번호 설정하기',
      'SET PASSWORD': '비밀번호 설정하기',
      'Leave page?\nChanges that you made may not be saved.':
        '페이지를 나가시겠습니까?\n변경 사항이 저장되지 않을 수 있습니다.',
      Logout: '로그아웃',
      'Edit profile': '프로필 수정하기',
      'Your profile was updated!': '당신의 프로필이 업데이트되었습니다',
      'Go back to': '다시 돌아가기',
      'chaos website': 'Chaos 홈페이지',
      'corona website': 'Corona website',
      'enscape website': 'Enscape website',
      'Loading your account...': '당신의 계정을 로딩중입니다...',
      'Loading your details...': '계정 세부정보를 로딩중입니다...',
      'First name': '이름',
      'Last name': '성',
      '(Optional)': '(선택사항)',
      'Customer Number': '고객번호',
      'Save changes': '변경사항 저장하기',
      'Change password': '비밀번호 변경하기',
      'Reload captcha': '보안문자 새로고침',
      'Please enter the numbers on the image': '이미지의 숫자를 입력하세요',
      Back: '돌아가기',
      'Almost there': '거의 완료되었습니다',
      'Create account': '계정 생성하기',
      "Let's be friends. It's free.": '같이 친구가 되어요. 무료입니다.',
      'privacy-text-chaos':
        '계속 진행하시면 자동으로 동의가 됩니다 <privacy>개인정보정책</privacy>, <terms>이용약관</terms> 그리고 <eula>EULA</eula>',
      'privacy-text-corona':
        '계속 진행하시면 자동으로 동의가 됩니다 <privacy>개인정보정책</privacy> 그리고 <terms>이용약관</terms>',
      'privacy-text-enscape': 'By continuing, I accept the terms of <eula>Enscape EULA</eula>',
      'privacy-text-trial':
        '이 버튼을 클릭하면 <privacy>개인 정보 보호 정책(Privacy Policy)</privacy>, <terms>이용 약관(Terms of Use)</terms> 및 <eula>EULA에 동의함을 확인하는 것입니다</eula>.',
      Next: '다음',
      'Complete your profile': '프로필 작성 마무리하기',
      'Country or Region': '국가 혹은 지역',
      newsletterSubscription: '독점 제공, 제품, 예정된 이벤트 및 웨비나에 대한 뉴스를 가끔 보내주십시오.',
      'Your account has been created.': '계정 생성이 완료되었습니다.',
      "with a link to activate your account. If you don't see the email, please check your spam folder or try":
        '계정을 활성화하는 링크가 포함되어 있습니다. 이메일이 보이지 않으면 스팸 폴더를 확인하십시오',
      sending: '보내기',
      'the email again.': '이메일 다시..',
      "We've resent the activation code successfully. If you still don't see the email, please":
        '활성화 코드를 성공적으로 재전송했습니다. 그래도 이메일이 보이지 않으면',
      contact: '문의하기',
      'our support team.': '고객 서포트팀.',
      'Link existing account': '기존 계정 연결',
      'Account with this email already exists.': '이 이메일과 연결된 계정이 이미 존재합니다.',
      Change: '변경하기',
      'Enter your Chaos password to link it to your': '연결하기 위해서 당신의 카오스그룹 비밀번호를 입력하십시오.',
      'account.': '계정.',
      'Chaos Password': 'Chaos 비밀번호',
      'LINK ACCOUNTS': '계정 연결',
      'We will use your Google details to create your Chaos account':
        '귀하의 Google 정보를 사용하여 Chaos 계정을 생성합니다.',
      'We will use your Facebook details to create your Chaos account':
        'Facebook 정보를 사용하여 Chaos 계정을 생성합니다.',
      'We will use your Trimble details to create your Chaos account':
        '귀하의 Trimble 세부 정보를 사용하여 Chaos 계정을 생성합니다.',
      Country: '국가',
      'Link an existing account': '기존 계정에 연결',
      'CREATE ACCOUNT': '계정 생성하기',
      'Account created': '계정 생성완료',
      'Your Chaos account is now active.': '이제 카오스 그룹 계정이 활성화되었습니다.',
      OK: 'OK',
      'A Chaos account has been created and linked to your':
        'Chaos 계정 생성이 완료되었고 당신의 계정으로 연결되었습니다',
      'Trimble Account.': 'Trimble 계정.',
      'Fast interactive rendering. New, smart functionality.': '빠른 인터랙티브 렌더링. 새롭고 스마트한 기능.',
      'Sign in with your Trimble account to start using V-Ray for SketchUp Studio':
        'V-Ray for SketchUp Studio 사용을 시작하려면 Trimble 계정으로 로그인하십시오.',
      'Loading...': '로딩중...',
      '404 – PAGE NOT FOUND': '404 – 페이지를 찾을 수 없습니다',
      'Please enter your username or email address.': '사용자 이름 또는 이메일 주소를 입력하십시오.',
      'Invalid activation link.': '잘못된 활성화 링크.',
      'Invalid delete account link.': '잘못된 계정 삭제 링크.',
      'Please enter your email address.': '이메일 주소를 입력하세요.',
      'Please enter valid email address.': '유효한 이메일 주소를 입력하세요.',
      'Invalid password.': '유효하지 않은 비밀번호.',
      'This email address is already connected to an account.': '이 이메일 주소는 이미 계정에 연결되어 있습니다.',
      instead: 'instead',
      'Please enter your password.': '비밀번호를 입력하세요.',
      'Your password must be at least 8 characters long.': '비밀번호는 8 자 이상이어야합니다.',
      'Your password must be at most 64 characters long.': '비밀번호는 최대 64 자 이내여야 합니다.',
      'Both lower and upper case letters': '소문자와 대문자 모두 포함되어야 합니다.',
      'At least one number': '숫자 1개 이상',
      'At least one symbol': '하나 이상의 특수문자',
      'At least 12 characters': '12자 이상',
      'Please enter your first name.': 'First name을 입력하세요.',
      'Your first name cannot be more than 72 characters long.': 'First name은 72자를 초과 할 수 없습니다.',
      'Please enter your last name.': 'Last name을 입력하세요.',
      'Your last name cannot be more than 53 characters long.': 'Last name은 53자를 초과 할 수 없습니다.',
      'Please enter your country.': '국가를 입력하세요.',
      'Non-Latin letters': '비 라틴 문자',
      'No Internet connection. Please check the network cables, modem and router or reconnect to Wi-Fi.':
        '인터넷에 연결되지 않음. 네트워크 케이블, 모뎀 및 라우터를 확인하거나 Wi-Fi에 다시 연결하십시오.',
      'We are having technical difficulties. Please try later.': '기술적인 문제가 있습니다. 나중에 다시 시도해주세요.',
      'An unexpected error occurred.': '예상치못한 에러가 발생하였습니다.',
      'Username or email': '이메일 혹은 유저네임',
      or: '혹은',
      'Oops... what a mess.': 'Oops... 문제가 생겼습니다.',
      Click: '클릭하기',
      here: '여기',
      'to try again.': '다시 시도하기.',
      'Show password': '비밀번호 보여주기',
      'Hide password': '비밀번호 숨기기',
      'Continue with corporate email': '법인 계정으로 계속하기',
      'Corporate sign in': '법인 계정 로그인',
      'Corporate email': '회사 이메일',
      'Using a corporate account?': '법인 계정을 사용하시겠습니까?',
      'There was a problem finalizing your authentication.': 'There was a problem finalizing your authentication.',
      'Explore Cosmos': 'Explore Cosmos',
      'Please choose a new password to access all Chaos products & services, including V-Ray, Corona, Vantage, and more.':
        'Please choose a new password to access all Chaos products & services, including V-Ray, Corona, Vantage, and more.',
      'Since we are merging your Chaos accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos accounts, please choose a new password to activate or manage your products & services.',
      'Since we are merging your Chaos and Enscape accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos and Enscape accounts, please choose a new password to activate or manage your products & services.',
      'Since we are merging your Chaos and Axyz accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos and Axyz accounts, please choose a new password to activate or manage your products & services.',
      'Since we are merging your Chaos and Corona accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos and Corona accounts, please choose a new password to activate or manage your products & services.',
      'Your account is temporary blocked due to security reasons. Please try again in one minute.':
        'Your account is temporarily unavailable. This may be due to using an incorrect password multiple times or other security reasons.<br/><br/> As the first step, try resetting your password by clicking on the "Forgot your password?" link below.',
      'Sign in to get your trial': 'Sign in to get your trial',
      'Sign in to complete your purchase': 'Sign in to complete your purchase',
      'Sign in to activate the Corona trial on your account': 'Sign in to activate the Corona trial on your account',
      'Create account to get your trial': 'Create account to get your trial',
      'We could not sign you in. If the problem persists, please':
        'We could not sign you in. If the problem persists, please',
      'Your user has been disabled in our system.': 'Your user has been disabled in our system.',
      'Sign in complete.': 'Sign in complete.',
      'You can now start using all Chaos products licensed to you':
        'You can now start using all Chaos products licensed to you',
      'You may now close this window.': 'You may now close this window.',
      'An error has occurred during authentication. Try to sign in again.':
        'An error has occurred during authentication. Try to sign in again.',
      'Still having problems signing in?': 'Still having problems signing in?',
      'Visit our Help Center.': 'Visit our Help Center.',
      'Work email': 'Work email',
      'You can organize and share your Enscape uploads, create Panorama Tours and manage licenses by accessing your account.':
        'You can organize and share your Enscape uploads, create Panorama Tours and manage licenses by accessing your account.',
      'Go to my.enscape3d.com': 'Go to my.enscape3d.com',
      'Authentication complete': 'Authentication complete',
      'You may now close this window and proceed to the Enscape Application':
        'You may now close this window and proceed to the Enscape Application',
      'You are now signed in as': 'You are now signed in as',
      'You may now close this window': 'You may now close this window',
      'and proceed to the Enscape Application': 'and proceed to the Enscape Application',
      'Enter your email.': '이메일을 입력해주세요',
      'You will use it to access your trial.': 'Chaos trial license 사용 시 사용됩니다.',
      'Work or educational email': '직장 또는 교육기관 이메일',
      'Create a Chaos account to start your free trial.': '무료 평가판을 시작하려면 Chaos 계정을 만들어주세요',
      'Your Chaos account has been created successfully.': '귀하의 카오스 계정이 성공적으로 생성되었습니다.',
      'Activation is required.': '계정 활성화가 필요합니다.',
      'Check your email': '이메일을 확인하세요',
      "We've sent an email to": '귀하의 계정을 활성화하고 평가판에 액세스할 수 있는 링크가 포함된 이메일을',
      'with a link to activate your account and access your trial.': '로 보냈습니다.',
      resend: '이메일이 보이지 않으면 스팸 폴더를 확인하거나<send>다시 전송하기</send>를 해주세요.',
      Continue: '계속 진행하기',
      'Your password should contain': '비밀번호에는 다음이 포함되어야 합니다.',
      'Change email': '이메일 변경하기',
      'Please enter a valid email.': '유효한 이메일을 입력하세요.',
      'Confirm your email.': '이메일을 확인하세요.',
      'Start your trial': '테스트 라이선스 시작하기',
    },
  },
  jp: {
    translation: {
      'Discourse Sign In': 'Discourse談話室にサインイン',
      'Oops...': 'Oops...',
      'Continue with Google': 'Googleでサインイン',
      'Sign in with Trimble': 'Trimbleでサインイン',
      'Continue with Trimble': 'Trimbleで進む',
      'Sign in with Facebook': 'Facebookでサインイン',
      'Simple, fast, smart cloud rendering': 'Simple, fast, smart cloud rendering',
      'Start with 20 free credits': '無料の20クレジットから開始',
      'A global computer graphics event for 3D artists and developers':
        'A global computer graphics event for 3D artists and developers',
      'Get your ticket': 'チケットを入手',
      'Epic CG event returns to Sofia': 'Epic CG event returns to Sofia',
      'Early Bird tickets out now': '早期割引チケット発売中',
      'Get tickets': 'チケットを入手',
      'New user?': 'アカウントはお持ちですか？',
      'Create Account': 'アカウント作成',
      'Sign in': 'サインイン',
      'Please wait...': '少しお待ちください',
      Password: 'Password',
      'Forgot your password?': 'パスワードをお忘れですか？',
      'Resend activation link': 'アクティベーションリンクを再送する',
      'A new activation link has been sent to': '新しいアクティベーション（有効化）リンクが送付されました',
      'Please use it to activate your account.': 'アカウントをアクティベート（有効化）してください。',
      'Your account is active': 'あなたのアカウントはアクティブです',
      'Welcome to Chaos Group!': 'Welcome to Chaos Group!',
      'Delete account': 'アカウントアカント削除',
      'Are you sure you want to delete all data associated with your account?':
        'アカウントに関連するすべてのデータを削除します。よろしいですか？',
      'Delete all data': '全てのデータを削除',
      'Thank you!': 'Thank you!',
      'All data was successfully deleted.': '全てのデータの削除に成功しました。',
      'We apologize for any inconvenience.': 'ご迷惑をおかけして申し訳ありません。',
      'Something went wrong.': '不具合が発生しました.',
      'Having troubles?': '問題を抱えてますか？',
      'If you experience problems, contact our': '問題が発生した場合は、こちらへご連絡ください。',
      'Customer Service.': 'カスタマーサービス',
      'Already have an account?': 'すでにアカウントをお持ちですか？',
      'Back to profile': 'プロフィールに戻る',
      'Change your password': 'パスワード変更',
      'Old password': '古いパスワード',
      'New password': '新しいパスワード',
      'Change Password': 'パスワード変更',
      'Password successfully updated. Redirecting you to login page...':
        'パスワードを更新しました。ログインページへのリダイレクトします...',
      "Enter your email and we'll": 'あなたのメールアドレスを入力してください。',
      'send you instructions to reset your password': 'パスワード再設定用のメールを送ります',
      Email: 'Email',
      Recover: '回復',
      "with a link to reset your password. If you don't see the email, please check your spam folder.":
        'パスワードをリセットするためのリンクが記載されています。メールが表示されない場合は、迷惑メールフォルダをご確認ください。',
      'Back to login': 'ログインに戻る',
      'LOADING...': 'LOADING...',
      'Request a new one': '新しいものをリクエストする',
      'Set your new password': '新しいパスワードを設定してください',
      '(12 or more characters)': '(12桁以上)',
      Update: '更新',
      'Set your password': 'パスワード設定',
      'SET PASSWORD': 'パスワード設定',
      'Leave page?\nChanges that you made may not be saved.':
        'このページから移動しますか？変更した内容は保存されない場合があります。',
      Logout: 'ログアウト',
      'Edit profile': 'プロフィール編集',
      'Your profile was updated!': 'プロフィールは更新されました！',
      'Go back to': '戻る',
      'Chaos Group website': 'Chaos Group website',
      'Loading your account...': 'アカウントをロードしてます…',
      'Loading your details...': '詳細情報をロードしています….',
      'First name': '名',
      'Last name': '姓',
      'Save changes': '変更を保存',
      'Change password': 'パスワードを変更',
      'Reload captcha': 'キャプチャを再読み込み',
      'Please enter the numbers on the image': '画像の数字を入力してください',
      Back: '戻る',
      'Almost there': 'もう少しです',
      'Create account': 'アカウント作成',
      "Let's be friends. It's free.": "Let's be friends. It's free.",
      'privacy-text-chaos':
        '継続することで、私は同意します <privacy>プライバシーポリシー</privacy>、<terms>利用規約</terms> と <eula>EULA</eula>',
      'privacy-text-corona':
        '継続することで、私は同意します <privacy>プライバシーポリシー</privacy> と <terms>利用規約</terms>',
      'privacy-text-enscape': 'By continuing, I accept the terms of <eula>Enscape EULA</eula>',
      'privacy-text-trial':
        '次のボタンをクリックすると、<privacy>プライバシーポリシー</privacy>、<terms>利用規約</terms>、<eula>EULAに同意することになります</eula>。',
      Next: 'Next',
      'Complete your profile': 'プロフィール完成',
      'Country or Region': '国または地域',
      newsletterSubscription: '限定商品、製品、イベント、ウェビナーなどのお知らせメールの配信を希望します。',
      'Your account has been created.': 'アカウントが作成されました。',
      "with a link to activate your account. If you don't see the email, please check your spam folder or try":
        'クリックすると、アカウントを有効化するためのリンクが表示されます。メールが表示されない場合は、迷惑メールフォルダを確認してください',
      sending: 'sending',
      'the email again.': 'the email again.',
      "We've resent the activation code successfully. If you still don't see the email, please":
        'アクティベーションコードの再送に成功しました。それでもメールが表示されない場合は、次のようにしてください。',
      contact: '問い合わせ',
      'our support team.': 'サポートチーム',
      'Link existing account': '既存のアカウントにリンクするt',
      'Account with this email already exists.': 'このメールのアカウントはすでに存在しています。.',
      Change: '変更',
      'Enter your Chaos Group password to link it to your':
        'カオスグループのパスワードを入力して、自分のアカウントにリンクさせてください。',
      'account.': 'アカウント',
      'Chaos Group Password': 'Chaos Group Password',
      'LINK ACCOUNTS': 'LINK ACCOUNTS',
      'We will use your Google details to create your Chaos Group account':
        'アカウントを作成するためにあなたのGoogleの情報を使用します',
      'We will use your Facebook details to create your Chaos Group account':
        'アカウントを作成するためにあなたのFacebookの情報を使用します',
      'We will use your Trimble details to create your Chaos Group account':
        'アカウントを作成するためにあなたのTrimbleの情報を使用します',
      Country: '国',
      'Link an existing account': '既存のアカウントへのリンク',
      'CREATE ACCOUNT': 'アカウント作成',
      'Account created': '作成されたアカウント',
      'Your Chaos Group account is now active.': 'アカウントがアクティブになりました。',
      OK: 'OK',
      'A Chaos Group account has been created and linked to your': 'アカウントが作成され、リンクされました',
      'Trimble Account.': 'Trimble Account.',
      'Fast interactive rendering. New, smart functionality.': 'Fast interactive rendering. New, smart functionality.',
      'Sign in with your Trimble account to start using V-Ray for SketchUp Studio':
        'Trimbleアカウントでサインインして、V-Ray for SketchUp Studioの使用を開始します。',
      'Loading...': 'ローディング…',
      '404 – PAGE NOT FOUND': '404 – PAGE NOT FOUND',
      'Please enter your username or email address.': 'ユーザー名またはメールアドレスを入力してください。',
      'Invalid activation link.': '無効なアクティベーションリンクです。',
      'Invalid delete account link.': '無効なアカウント削除リンクです。',
      'Please enter your email address.': 'メールアドレスを入力してください。',
      'Please enter valid email address.': '有効なメールアドレスを入力してください。',
      'Invalid password.': '無効なパスワード.',
      'This email address is already connected to an account.': 'このメールアドレスは既にアカウントに繋がっています。',
      'This email is already taken.': 'このメールはすでに存在しています。.',
      'Please enter your password.': 'パスワードを入力してください。',
      'Your password must be at least 8 characters long.': 'パスワードは8文字以上でなければなりません。',
      'Your password must be at most 64 characters long.': 'パスワードは最大64文字まです。',
      'Both lower and upper case letters': '少なくとも1つの小文字と1つの大文字',
      'At least one number': '少なくとも1つの番号',
      'At least one symbol': '少なくとも1つの特殊文字',
      'At least 12 characters': '少なくとも12文字',
      'Please enter your first name.': '名(First name)を入力してください。',
      'Your first name cannot be more than 72 characters long.': '名(First name)は72文字を超えてはいけません。',
      'Please enter your last name.': '姓(Last name)を入力してください。',
      'Your last name cannot be more than 53 characters long.': '姓(Last name)は53文字を超えてはいけません。',
      'Please enter your country.': 'あなたの国を入力してください。',
      'No Internet connection. Please check the network cables, modem and router or reconnect to Wi-Fi.':
        'インターネットに接続されていません。ネットワークケーブル、モデム、ルーターをご確認いただくか、Wi-Fiに再接続してください。',
      'We are having technical difficulties. Please try later.':
        '技術的な問題が発生しています。少し時間をあけて再度お試しください。',
      'An unexpected error occurred.': '予期せぬエラーが発生しました。',
      'Username or email': 'Username or email',
      or: 'or',
      'Oops... what a mess.': 'Oops... what a mess.',
      Click: 'クリック',
      here: 'here',
      'to try again.': 'もう一度試してみてください。',
      'Continue with corporate email': 'コーポレートアカウントで進む',
      'Corporate Sign In': 'コーポレートサインイン',
      'Corporate email': 'コーポレートメール',
      'Using a corporate account?': 'コーポレートアカウントを使用していますか？',
      'There was a problem finalizing your authentication.': '最終認証に問題がありました。',
      'Welcome to Chaos!': 'Welcome to Chaos!',
      '(Optional)': '(オプション)',
      'Customer Number': '顧客番号',
      '404 – Page not found': '404 – Page not found',
      'Non-Latin letters': 'アルファベットで記入してください',
      'Show password': 'パスワードを表示',
      'Hide password': 'パスワードを隠す',
      'Please choose a new password to access all Chaos products & services, including V-Ray, Corona, Vantage, and more.':
        'Please choose a new password to access all Chaos products & services, including V-Ray, Corona, Vantage, and more.',
      'Since we are merging your Chaos accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos accounts, please choose a new password to activate or manage your products & services.',
      'Since we are merging your Chaos and Enscape accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos and Enscape accounts, please choose a new password to activate or manage your products & services.',
      'Since we are merging your Chaos and Axyz accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos and Axyz accounts, please choose a new password to activate or manage your products & services.',
      'Since we are merging your Chaos and Corona accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos and Corona accounts, please choose a new password to activate or manage your products & services.',
      'Your account is temporary blocked due to security reasons. Please try again in one minute.':
        'Your account is temporarily unavailable. This may be due to using an incorrect password multiple times or other security reasons.<br/><br/> As the first step, try resetting your password by clicking on the "Forgot your password?" link below.',
      'Sign in to get your trial': 'Sign in to get your trial',
      'Sign in to complete your purchase': 'Sign in to complete your purchase',
      'Sign in to activate the Corona trial on your account': 'Sign in to activate the Corona trial on your account',
      'Create account to get your trial': 'Create account to get your trial',
      'We could not sign you in. If the problem persists, please':
        'We could not sign you in. If the problem persists, please',
      'Your user has been disabled in our system.': 'Your user has been disabled in our system.',
      'Sign in complete.': 'Sign in complete.',
      'You can now start using all Chaos products licensed to you':
        'You can now start using all Chaos products licensed to you',
      'You may now close this window.': 'You may now close this window.',
      'An error has occurred during authentication. Try to sign in again.':
        'An error has occurred during authentication. Try to sign in again.',
      'Still having problems signing in?': 'Still having problems signing in?',
      'Visit our Help Center.': 'Visit our Help Center.',
      'Work email': 'Work email',
      'You can organize and share your Enscape uploads, create Panorama Tours and manage licenses by accessing your account.':
        'You can organize and share your Enscape uploads, create Panorama Tours and manage licenses by accessing your account.',
      'Go to my.enscape3d.com': 'Go to my.enscape3d.com',
      'Authentication complete': 'Authentication complete',
      'You may now close this window and proceed to the Enscape Application':
        'You may now close this window and proceed to the Enscape Application',
      'You are now signed in as': 'You are now signed in as',
      'You may now close this window': 'You may now close this window',
      'and proceed to the Enscape Application': 'and proceed to the Enscape Application',
      'Enter your email.': 'メールを入力してください',
      'You will use it to access your trial.': 'トライアルライセンスを使用する時に使います。',
      'Work or educational email': '職場または教育機関のメールアドレス',
      'Create a Chaos account to start your free trial.':
        '無料トライアルを開始するには、Chaosアカウントを作成してください。',
      'Your Chaos account has been created successfully.': 'Chaosアカウントが正常に作成されました。',
      'Activation is required.': 'アクティベーションが必要です。',
      'Check your email': '様、メールをご確認ください。',
      "We've sent an email to":
        'お客様のアカウントを有効にし、トライアル版にアクセスできるリンクを含むアクティベーションメールを',
      'with a link to activate your account and access your trial.': 'に送信しました。',
      resend: 'メールが届かない場合は、迷惑メールの確認または<send>再送信</send>してください。',
      Continue: '続ける',
      'Your password should contain': 'パスワードには以下を含める必要があります:',
      'Change email': 'メールアドレス変更',
      'Please enter a valid email.': '有効なメールアドレスを入力してください。',
      'Confirm your email.': 'メールをご確認ください。',
      'Start your trial': 'トライアルを開始します',
    },
  },
  de: {
    translation: {
      'Discourse Sign In': 'Discourse Anmelden',
      'Oops...': 'Hoppla...',
      'Continue with Google': 'Fortfahren mit Google',
      'Sign in with Trimble': 'Anmelden mit Trimble',
      'Continue with Trimble': 'Fortfahren mit Trimble',
      'Sign in with Facebook': 'Anmelden mit Facebook',
      'Simple, fast, smart cloud rendering': 'Einfaches, schnelles, intelligentes Cloud-Rendering',
      'Start with 20 free credits': 'Starten Sie mit 20 kostenlosen Credits',
      'A global computer graphics event for 3D artists and developers':
        'Ein globales Computergrafik-Event für 3D-Künstler und -Entwickler',
      'Get your ticket': 'Sichern Sie sich Ihre Eintrittskarte',
      'Epic CG event returns to Sofia': 'Spektakuläres CG-Event kehrt nach Sofia zurück',
      'Early Bird tickets out now': 'Frühbucherkarten jetzt erhältlich',
      'Get tickets': 'Sichern Sie sich eine Eintrittskarte',
      'New user?': 'Neuer Benutzer?',
      'Create Account': 'Konto erstellen',
      'Sign in': 'Anmelden',
      'Please wait...': 'Bitte warten...',
      Password: 'Passwort',
      'Forgot your password?': 'Sie haben Ihr Passwort vergessen?',
      'Resend activation link': 'Aktivierungslink erneut senden',
      'A new activation link has been sent to': 'Ein neuer Aktivierungslink wurde gesendet an',
      'Please use it to activate your account.': 'Klicken Sie auf den Link, um Ihr Konto zu aktivieren.',
      'Your account is active': 'Ihr Konto wurde aktiviert',
      'Welcome to Chaos!': 'Willkommen bei Chaos!',
      'Welcome to Corona!': 'Willkommen bei Corona!',
      'Delete account': 'Konto Löschen',
      'Are you sure you want to delete all data associated with your account?':
        'Möchten Sie wirklich alle mit Ihrem Konto verbundenen Daten löschen?',
      'Delete all data': 'Alle Daten löschen',
      'Thank you!': 'Vielen Dank!',
      'All data was successfully deleted.': 'Alle Daten wurden erfolgreich gelöscht.',
      'We apologize for any inconvenience.': 'Wir entschuldigen uns für etwaige Unannehmlichkeiten.',
      'Something went wrong.': 'Es ist ein Fehler aufgetreten.',
      'Having troubles?': 'Sind Probleme aufgetreten?',
      'If you experience problems, contact our': 'Sollten Sie auf Probleme treffen, wenden Sie sich bitte an unseren',
      'Customer Service.': 'Customer service.',
      'Already have an account?': 'Sie haben bereits ein Konto?',
      'Back to profile': 'Zurück zum Profil',
      'Change your password': 'Passwort ändern',
      'Old password': 'Altes Passwort',
      'New password': 'Neues Passwort',
      'Change Password': 'Passwort ändern',
      'Password successfully updated. Redirecting you to login page...':
        'Passwort erfolgreich aktualisiert. Wir leiten Sie auf die Anmeldeseite weiter...',
      "Enter your email and we'll": 'Geben Sie Ihre E-Mail-Adresse ein und wir',
      'send you instructions to reset your password': 'senden Ihnen eine Anleitung zum Zurücksetzen Ihres Passworts',
      Email: 'E-Mail-Adresse',
      Recover: 'Wiederherstellen',
      "with a link to reset your password. If you don't see the email, please check your spam folder.":
        'mit einem Link zum Zurücksetzen Ihres Passworts. Sollten Sie die E-Mail nicht sehen, schauen Sie bitte in Ihrem Spam-Ordner nach.',
      'Back to login': 'Zurück zur Anmeldung',
      'LOADING...': 'Laden...',
      'Request a new one': 'Neues anfordern',
      'Set your new password': 'Neues Passwort festlegen',
      '(12 or more characters)': '(mindestens 12 Zeichen)',
      Update: 'Aktualisieren',
      'Set your password': 'Passwort festlegen',
      'SET PASSWORD': 'PASSWORT FESTLEGEN',
      'Leave page?\nChanges that you made may not be saved.':
        'Seite verlassen?\nVorgenommene Änderungen werden möglicherweise nicht gespeichert.',
      Logout: 'Logout',
      'Edit profile': 'Profil bearbeiten',
      'Your profile was updated!': 'Ihr Profil wurde aktualisiert!',
      'Go back to': 'Zurück zu',
      'chaos website': 'Chaos-Website',
      'corona website': 'Corona-Website',
      'enscape website': 'Enscape-Website',
      'Loading your account...': 'Ihr Konto wird geladen...',
      'Loading your details...': 'Ihre Details werden geladen...',
      'First name': 'Vorname',
      'Last name': 'Nachname',
      '(Optional)': '(Optional)',
      'Customer Number': 'Kundennummer',
      'Save changes': 'Änderungen speichern',
      'Change password': 'Passwort ändern',
      'Reload captcha': 'Captcha erneut laden',
      'Please enter the numbers on the image': 'Geben Sie die Zahlen auf dem Bild ein.',
      Back: 'Zurück',
      'Almost there': 'Fast fertig',
      'Create account': 'Konto erstellen',
      "Let's be friends. It's free.": 'Lassen Sie uns Freunde sein. Es ist kostenlos.',
      Next: 'Weiter',
      'Complete your profile': 'Profil vervollständigen',
      'Country or Region': 'Land oder Region',
      newsletterSubscription:
        'Senden Sie mir gelegentlich Neuigkeiten zu exklusiven Angeboten, Produkten, bevorstehenden Veranstaltungen und Webinaren. Sie können sich jederzeit abmelden.',
      'Your account has been created.': 'Ihr Konto wurde erstellt.',
      "with a link to activate your account. If you don't see the email, please check your spam folder or try":
        'mit einem Link zum Aktivieren Ihres Kontos. Wenn Sie die E-Mail nicht sehen, schauen Sie bitte in Ihrem Spam-Ordner nach oder',
      sending: 'senden Sie',
      'the email again.': 'die E-Mail erneut.',
      "We've resent the activation code successfully. If you still don't see the email, please":
        'Wir haben den Aktivierungscode erneut gesendet. Sollten Sie die E-Mail nicht sehen,',
      contact: 'wenden Sie sich an',
      'our support team.': 'unser Support-Team.',
      'Link existing account': 'Bestehendes Konto verknüpfen',
      'Account with this email already exists.': 'Ein Konto mit dieser E-Mail-Adresse ist bereits vorhanden.',
      Change: 'Ändern',
      'Enter your Chaos password to link it to your': 'Geben Sie Ihr Chaos-Passwort ein, um es mit Ihrem',
      'account.': 'Konto zu verknüpfen.',
      'Chaos Password': 'Chaos-Passwort',
      'LINK ACCOUNTS': 'KONTEN VERKNÜPFEN',
      'We will use your Google details to create your Chaos account':
        'Wir benutzen Ihre Google-Angaben, um Ihr Chaos-Konto zu erstellen.',
      'We will use your Facebook details to create your Chaos account':
        'Wir benutzen Ihre Facebook-Angaben, um Ihr Chaos-Konto zu erstellen.',
      'We will use your Trimble details to create your Chaos account':
        'Wir benutzen Ihre Trimble-Angaben, um Ihr Chaos-Konto zu erstellen.',
      Country: 'Land',
      'Link an existing account': 'Ein bestehendes Konto verknüpfen',
      'CREATE ACCOUNT': 'KONTO ERSTELLEN',
      'Account created': 'Konto erstellt',
      'Your Chaos account is now active.': 'Ihr Chaos-Konto ist jetzt aktiv.',
      OK: 'OK',
      'A Chaos account has been created and linked to your': 'Es wurde ein Chaos-Konto erstellt und mit Ihrem',
      'Trimble Account.': 'Trimble-Konto verknüpft.',
      'Fast interactive rendering. New, smart functionality.':
        'Schnelles, interaktives Rendering. Neue, intelligente Funktion.',
      'Sign in with your Trimble account to start using V-Ray for SketchUp Studio':
        'Melden Sie sich mit Ihrem Trimble-Konto an, um V-Ray für SketchUp Studio zu benutzen.',
      'Loading...': 'Laden...',
      '404 – PAGE NOT FOUND': '404 - SEITE NICHT GEFUNDEN',
      'Please enter your username or email address.': 'Geben Sie Ihren Benutzernamen oder Ihre E-Mail Adresse ein.',
      'Invalid activation link.': 'Ungültiger Aktivierungslink.',
      'Invalid delete account link.': 'Ungültiger Link zur Kontolöschung.',
      'Please enter your email address.': 'Geben Sie Ihre E-Mail Adresse ein.',
      'Please enter valid email address.': 'Geben Sie eine gültige E-Mail-Adresse ein.',
      'Invalid password.': 'Ungültiges Passwort.',
      'This email address is already connected to an account.':
        'Diese E-Mail-Adresse ist bereits mit einem Konto verbunden.',
      instead: 'stattdessen',
      'Please enter your password.': 'Geben Sie Ihr Passwort ein.',
      'Your password must be at least 8 characters long.': 'Ihr Passwort muss mindestens 8 Zeichen enthalten.',
      'Your password must be at most 64 characters long.': 'Ihr Passwort darf höchstens 64 Zeichen enthalten.',
      'Both lower and upper case letters': 'Klein- und Großbuchstaben',
      'At least one number': 'Mindestens eine Zahl',
      'At least one symbol': 'Mindestens ein Sonderzeichen',
      'At least 12 characters': 'Mindestens 12 Zeichen',
      'Please enter your first name.': 'Geben Sie Ihren Vornamen ein.',
      'Your first name cannot be more than 72 characters long.':
        'Ihr Vorname darf nicht mehr als 72 Zeichen enthalten.',
      'Please enter your last name.': 'Geben Sie Ihren Nachnamen ein.',
      'Your last name cannot be more than 53 characters long.':
        'Ihr Nachname darf nicht mehr als 53 Zeichen enthalten.',
      'Please enter your country.': 'Geben Sie Ihr Land ein.',
      'Non-Latin letters': 'Nichtlateinische Zeichen',
      'No Internet connection. Please check the network cables, modem and router or reconnect to Wi-Fi.':
        'Keine Internetverbindung Überprüfen Sie Verkabelung, Modem und Router oder stellen Sie die Verbindung zum WLAN wieder her.',
      'We are having technical difficulties. Please try later.':
        'Es gibt technische Schwierigkeiten. Versuchen Sie es später erneut.',
      'An unexpected error occurred.': 'Ein unerwarteter Fehler ist aufgetreten.',
      'Username or email': 'Benutzername oder E-Mail-Adresse',
      or: 'oder',
      'Oops... what a mess.': 'Hoppla... was für ein Chaos.',
      Click: 'Klicken Sie',
      here: 'hier',
      'to try again.': 'und versuchen Sie es erneut.',
      'Show password': 'Passwort anzeigen',
      'Hide password': 'Passwort verbergen',
      'Continue with corporate email': 'Fortfahren mit geschäftlicher E-Mail-Adresse',
      'Corporate sign in': 'Anmelden mit geschäftlicher E-Mail-Adresse',
      'Corporate email': 'Geschäftliche E-Mail-Adresse',
      'Using a corporate account?': 'Haben Sie ein geschäftliches Konto?',
      'There was a problem finalizing your authentication.': 'Bei der Authentifizierung ist ein Problem aufgetreten.',
      'Explore Cosmos': 'Cosmos entdecken',
      'Please choose a new password to access all Chaos products & services, including V-Ray, Corona, Vantage, and more.':
        'Wählen Sie ein neues Passwort, um auf alle Chaos-Produkte und -Dienste zuzugreifen, einschließlich V-Ray, Corona, Vantage und mehr.',
      'Since we are merging your Chaos accounts, please choose a new password to activate or manage your products & services.':
        'Wir führen Ihre Chaos-Konten zusammen. Wählen Sie ein neues Passwort, um Ihre Produkte und Dienste zu aktivieren oder zu verwalten.',
      'Since we are merging your Chaos and Enscape accounts, please choose a new password to activate or manage your products & services.':
        'Wir führen Ihre Chaos- und Enscape-Konten zusammen. Wählen Sie ein neues Passwort, um Ihre Produkte und Dienste zu aktivieren oder zu verwalten.',
      'Since we are merging your Chaos and Axyz accounts, please choose a new password to activate or manage your products & services.':
        'Wir führen Ihre Chaos- und Axyz-Konten zusammen. Wählen Sie ein neues Passwort, um Ihre Produkte und Dienste zu aktivieren oder zu verwalten.',
      'Since we are merging your Chaos and Corona accounts, please choose a new password to activate or manage your products & services.':
        'Wir führen Ihre Chaos- und Corona-Konten zusammen. Wählen Sie ein neues Passwort, um Ihre Produkte und Dienste zu aktivieren oder zu verwalten.',
      'privacy-text-chaos':
        'Indem ich fortfahre, akzeptiere ich die <privacy>Datenschutzrichtlinie</privacy>, die <terms>Nutzungsbedingungen</terms> und die <eula>EULA</eula>',
      'privacy-text-axyz':
        'By continuing, I accept the <privacy>Privacy Policy</privacy>, <terms>Terms of Use</terms> and <eula>EULA</eula>',
      'privacy-text-corona':
        'By continuing, I accept the <privacy>Privacy Policy</privacy> and <terms>Terms of Use</terms>',
      'privacy-text-enscape': 'By continuing, I accept the terms of <eula>Enscape EULA</eula>',
      'privacy-text-trial':
        'Indem ich fortfahre, akzeptiere ich die <privacy>Datenschutzrichtlinie</privacy>, die <terms>Nutzungsbedingungen</terms> und die <eula>EULA</eula>',
      'Your account is temporary blocked due to security reasons. Please try again in one minute.':
        'Ihr Konto ist aus Sicherheitsgründen vorübergehend gesperrt. Versuchen Sie es in einer Minute erneut.',
      'Sign in to get your trial': 'Anmelden und Testversion erhalten',
      'Create account to get your trial': 'Konto erstellen, um Testversion zu erhalten',
      'We could not sign you in. If the problem persists, please':
        'Wir konnten Ihre Anmeldung nicht durchführen. Wenn das Problem weiterhin besteht,',
      'Your user has been disabled in our system.': 'Ihr Benutzer wurde in unserem System deaktiviert.',
      'Sign in to complete your purchase': 'Melden Sie sich an, um den Einkauf abzuschließen',
      'Sign in to activate the Corona trial on your account':
        'Melden Sie sich an, um die Corona-Testversion in Ihrem Konto zu aktivieren',
      'Create account to complete your purchase': 'Erstellen Sie ein Konto, um Ihren Einkauf abzuschließen',
      'Sign in complete.': 'Anmeldung abgeschlossen.',
      'You can now start using all Chaos products licensed to you':
        'Sie können nun alle für Sie lizenzierten Chaos-Produkte nutzen',
      'You may now close this window.': 'Sie können dieses Fenster nun schließen.',
      'An error has occurred during authentication. Try to sign in again.':
        'Bei der Authentifizierung ist ein Fehler aufgetreten. Versuchen Sie, sich erneut anzumelden.',
      'Still having problems signing in?': 'Haben Sie immer noch Probleme bei der Anmeldung?',
      'Visit our Help Center.': 'Besuchen Sie unser Help-Center.',
      'Sign in with a different account?': 'Mit einem anderen Konto anmelden?',
      'Go to chaos.com': 'Gehen Sie zu chaos.com',
      'You are now signed out of all Chaos products licensed to you':
        'Sie sind nun von allen Chaos-Produkten abgemeldet, die für Sie lizenziert sind',
      'Sign out complete.': 'Abmeldung abgeschlossen.',
      'Work email': 'Geschäftliche E-Mail-Adresse',
      'You can organize and share your Enscape uploads, create Panorama Tours and manage licenses by accessing your account.':
        'Sie können Ihre Enscape-Uploads organisieren und freigeben, Panoramatouren erstellen und Lizenzen verwalten, indem Sie auf Ihr Konto zugreifen.',
      'Go to my.enscape3d.com': 'Gehen Sie zu my.enscape3d.com.',
      'Authentication complete': 'Authentifizierung abgeschlossen',
      'You may now close this window and proceed to the Enscape Application':
        'Sie können dieses Fenster nun schließen und mit der Enscape-Anwendung fortfahren',
      'You are now signed in as': 'Sie sind jetzt angemeldet als',
      'You may now close this window': 'Sie können dieses Fenster nun schließen',
      'and proceed to the Enscape Application': 'und mit der Enscape-Anwendung fortfahren',
      'Enter your email.': 'Geben Sie Ihre E-Mail-Adresse ein.',
      'You will use it to access your trial.': 'Damit erhalten Sie Zugang zu Ihrer Chaos-Testversion.',
      'Work or educational email': 'E-Mail-Adresse',
      'Create a Chaos account to start your free trial.':
        'Erstellen Sie ein Chaos-Konto, um Ihre kostenlose Testversion zu starten.',
      'Your Chaos account has been created successfully.': 'Ihr Chaos-Konto wurde erstellt.',
      'Activation is required.': 'Es ist eine Aktivierung erforderlich.',
      'Check your email': 'Schauen Sie in Ihren E-Mail-Ordner',
      "We've sent an email to": 'Wir haben eine E-Mail gesendet an',
      'with a link to activate your account and access your trial.':
        'mit einem Link zur Aktivierung Ihres Kontos und zum Zugriff auf Ihre Testversion.',
      resend:
        'Sollten Sie die E-Mail nicht sehen, schauen Sie in Ihrem Spam-Ordner nach oder <send>senden Sie die E-Mail erneut</send>.',
      Continue: 'Fortfahren',
      'Your password should contain': 'Ihr Passwort sollte enthalten',
      'Change email': 'E-Mail-Adresse ändern',
      'Please enter a valid email.': 'Geben Sie eine gültige E-Mail-Adresse ein.',
      'Confirm your email.': 'Bestätigen Sie Ihre E-Mail-Adresse.',
      'Start your trial': 'Starten Sie Ihre kostenlose Testversion',
    },
  },
  fr: {
    translation: {
      'Discourse Sign In': 'Connexion à Discourse',
      'Oops...': 'Oups...',
      'Continue with Google': 'Continuer avec Google',
      'Sign in with Trimble': 'Se connecter avec Trimble',
      'Continue with Trimble': 'Continuer avec Trimble',
      'Sign in with Facebook': 'Se connecter avec Facebook',
      'Simple, fast, smart cloud rendering': 'Rendu dans le Cloud simple, rapide et intelligent',
      'Start with 20 free credits': 'Commencer avec 20 crédits gratuits',
      'A global computer graphics event for 3D artists and developers':
        'Un événement mondial dans le domaine de l’infographie pour les artistes 3D et les développeurs',
      'Get your ticket': 'Obtenez votre billet',
      'Epic CG event returns to Sofia': 'Le fabuleux événement CG est de retour à Sofia',
      'Early Bird tickets out now':
        'Tickets à tarif préférentiel pour les premiers inscrits disponibles dès maintenant',
      'Get tickets': 'Obtenir des billets',
      'New user?': 'Nouvel utilisateur ?',
      'Create Account': 'Créer un compte',
      'Sign in': 'Se connecter',
      'Please wait...': 'Veuillez patienter...',
      Password: 'Mot de passe',
      'Forgot your password?': 'Mot de passe oublié ?',
      'Resend activation link': 'Renvoyer le lien d’activation',
      'A new activation link has been sent to': 'Un nouveau lien d’activation a été envoyé à l’adresse',
      'Please use it to activate your account.': 'Veuillez l’utiliser pour activer votre compte.',
      'Your account is active': 'Votre compte est actif',
      'Welcome to Chaos!': 'Bienvenue sur Chaos !',
      'Welcome to Corona!': 'Bienvenue sur Corona !',
      'Delete account': 'Supprimer le compte',
      'Are you sure you want to delete all data associated with your account?':
        'Voulez-vous vraiment supprimer toutes les données associées à votre compte ?',
      'Delete all data': 'Supprimer toutes les données',
      'Thank you!': 'Merci !',
      'All data was successfully deleted.': 'Toutes les données ont bien été supprimées.',
      'We apologize for any inconvenience.': 'Veuillez nous excuser pour les désagréments occasionnés.',
      'Something went wrong.': 'Un problème est survenu.',
      'Having troubles?': 'Vous rencontrez des difficultés ?',
      'If you experience problems, contact our': 'Si vous rencontrez des difficultés, contactez notre',
      'Customer Service.': 'Service client.',
      'Already have an account?': 'Vous avez déjà un compte ?',
      'Back to profile': 'Retour au profil',
      'Change your password': 'Modifier votre mot de passe',
      'Old password': 'Ancien mot de passe',
      'New password': 'Nouveau mot de passe',
      'Change Password': 'Modifier le mot de passe',
      'Password successfully updated. Redirecting you to login page...':
        'Le mot de passe a bien été mis à jour. Nous vous redirigeons vers la page de connexion...',
      "Enter your email and we'll": 'Saisissez votre adresse e-mail et nous vous',
      'send you instructions to reset your password':
        'enverrons des instructions pour réinitialiser votre mot de passe',
      Email: 'Adresse e-mail',
      Recover: 'Récupérer',
      "with a link to reset your password. If you don't see the email, please check your spam folder.":
        'avec un lien pour réinitialiser votre mot de passe. Si vous ne voyez pas l’e-mail, vérifiez votre dossier de spams.',
      'Back to login': 'Retour à la page de connexion',
      'LOADING...': 'CHARGEMENT…',
      'Request a new one': 'Demander un nouveau mot de passe',
      'Set your new password': 'Définir votre nouveau mot de passe',
      '(12 or more characters)': '(au moins 12 caractères)',
      Update: 'Mettre à jour',
      'Set your password': 'Définir votre mot de passe',
      'SET PASSWORD': 'DÉFINIR LE MOT DE PASSE',
      'Leave page?\nChanges that you made may not be saved.':
        'Quitter la page?\nIl est possible que les modifications apportées ne soient pas enregistrées.',
      Logout: 'Déconnexion',
      'Edit profile': 'Modifier le profil',
      'Your profile was updated!': 'Votre profil a été mis à jour !',
      'Go back to': 'Retour au',
      'chaos website': 'site Web de Chaos',
      'corona website': 'site Web de Corona',
      'enscape website': 'site Web de Enscape',
      'Loading your account...': 'Chargement de votre compte...',
      'Loading your details...': 'Chargement de vos informations...',
      'First name': 'Prénom',
      'Last name': 'Nom',
      '(Optional)': '(facultatif)',
      'Customer Number': 'Numéro de client',
      'Save changes': 'Enregistrer les modifications',
      'Change password': 'Modifier le mot de passe',
      'Reload captcha': 'Recharger le captcha',
      'Please enter the numbers on the image': 'Veuillez saisir les chiffres à l’image',
      Back: 'Retour',
      'Almost there': 'Nous avons presque terminé',
      'Create account': 'Créer un compte',
      "Let's be friends. It's free.": 'Soyons amis. C’est gratuit.',
      Next: 'Suivant',
      'Complete your profile': 'Compléter votre profil',
      'Country or Region': 'Pays ou région',
      newsletterSubscription:
        'Envoyez-moi occasionnellement des nouvelles sur les offres exclusives, les produits, les événements à venir et les webinaires. Vous pouvez vous désinscrire à tout moment.',
      'Your account has been created.': 'Votre compte a été créé.',
      "with a link to activate your account. If you don't see the email, please check your spam folder or try":
        'avec un lien pour activer votre compte. Si vous ne voyez pas l’e-mail, vérifiez votre dossier de spams ou essayez',
      sending: 'de renvoyer',
      'the email again.': 'l’e-mail.',
      "We've resent the activation code successfully. If you still don't see the email, please":
        'Le code d’activation a bien été envoyé. Si vous ne voyez pas l’e-mail, veuillez',
      contact: 'contacter',
      'our support team.': 'notre équipe d’assistance technique.',
      'Link existing account': 'Lier un compte existant',
      'Account with this email already exists.': 'Il existe déjà un compte associé à cette adresse e-mail.',
      Change: 'Modifier',
      'Enter your Chaos password to link it to your':
        'Saisissez votre mot de passe Chaos pour lier cette adresse à votre',
      'account.': 'compte.',
      'Chaos Password': 'Mot de passe Chaos',
      'LINK ACCOUNTS': 'LIER LES COMPTES',
      'We will use your Google details to create your Chaos account':
        'Nous utiliserons vos informations Google pour créer votre compte Chaos',
      'We will use your Facebook details to create your Chaos account':
        'Nous utiliserons vos informations Facebook pour créer votre compte Chaos',
      'We will use your Trimble details to create your Chaos account':
        'Nous utiliserons vos informations Trimble pour créer votre compte Chaos',
      Country: 'Pays',
      'Link an existing account': 'Lier un compte existant',
      'CREATE ACCOUNT': 'CRÉER UN COMPTE',
      'Account created': 'Compte créé',
      'Your Chaos account is now active.': 'Votre compte Chaos est maintenant actif.',
      OK: 'OK',
      'A Chaos account has been created and linked to your': 'Un compte Chaos a été créé et lié à votre',
      'Trimble Account.': 'Compte Trimble.',
      'Fast interactive rendering. New, smart functionality.':
        'Rendu interactif rapide. Nouvelle fonctionnalité intelligente',
      'Sign in with your Trimble account to start using V-Ray for SketchUp Studio':
        'Connectez-vous avec votre compte Trimble pour commencer à utiliser V-Ray for SketchUp Studio',
      'Loading...': 'Chargement…',
      '404 – PAGE NOT FOUND': '404 - PAGE INTROUVABLE',
      'Please enter your username or email address.':
        'Veuillez saisir votre nom d’utilisateur ou votre adresse e-mail.',
      'Invalid activation link.': 'Lien d’activation non valide.',
      'Invalid delete account link.': 'Lien de suppression de compte non valide.',
      'Please enter your email address.': 'Veuillez saisir votre adresse e-mail.',
      'Please enter valid email address.': 'Veuillez saisir une adresse e-mail valide.',
      'Invalid password.': 'Mot de passe non valide.',
      'This email address is already connected to an account.': 'Cette adresse e-mail est déjà associée à un compte.',
      instead: 'Essayez plutôt de',
      'Please enter your password.': 'Veuillez saisir votre mot de passe.',
      'Your password must be at least 8 characters long.': 'Votre mot de passe doit contenir au moins 8 caractères.',
      'Your password must be at most 64 characters long.': 'Votre mot de passe doit contenir au plus 64 caractères.',
      'Both lower and upper case letters': 'Une combinaison de minuscules et de majuscules',
      'At least one number': 'Au moins un chiffre',
      'At least one symbol': 'Au moins un symbole',
      'At least 12 characters': 'Au moins 12 caractères',
      'Please enter your first name.': 'Veuillez saisir votre prénom.',
      'Your first name cannot be more than 72 characters long.':
        'Votre prénom ne peut pas contenir plus de 72 caractères.',
      'Please enter your last name.': 'Veuillez saisir votre nom.',
      'Your last name cannot be more than 53 characters long.': 'Votre nom ne peut pas contenir plus de 53 caractères.',
      'Please enter your country.': 'Veuillez saisir votre pays.',
      'Non-Latin letters': 'Lettres non issues de l’alphabet latin',
      'No Internet connection. Please check the network cables, modem and router or reconnect to Wi-Fi.':
        'Aucune connexion Internet. Veuillez vérifier les câbles réseau, le modem et le routeur ou reconnectez-vous au réseau Wi-Fi.',
      'We are having technical difficulties. Please try later.':
        'Nous rencontrons des difficultés techniques. Veuillez réessayer ultérieurement.',
      'An unexpected error occurred.': 'Une erreur inattendue est survenue.',
      'Username or email': 'Nom d’utilisateur ou adresse e-mail',
      or: 'ou',
      'Oops... what a mess.': 'Oups... quelle pagaille.',
      Click: 'Cliquez',
      here: 'ici',
      'to try again.': 'pour réessayer.',
      'Show password': 'Afficher le mot de passe',
      'Hide password': 'Masquer le mot de passe',
      'Continue with corporate email': 'Continuer avec l’adresse e-mail de l’entreprise',
      'Corporate sign in': 'Nom d’utilisateur de l’entreprise',
      'Corporate email': 'Adresse e-mail de l’entreprise',
      'Using a corporate account?': 'Vous utilisez un compte d’entreprise ?',
      'There was a problem finalizing your authentication.':
        'Un problème est survenu lors de la dernière étape de votre authentification.',
      'Explore Cosmos': 'Explorer Cosmos',
      'Please choose a new password to access all Chaos products & services, including V-Ray, Corona, Vantage, and more.':
        'Veuillez choisir un nouveau mot de passe pour accéder à tous les produits et services Chaos, dont V-Ray, Corona, Vantage, et bien plus encore.',
      'Since we are merging your Chaos accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos accounts, please choose a new password to activate or manage your products & services.',
      'Since we are merging your Chaos and Enscape accounts, please choose a new password to activate or manage your products & services.':
        'Étant donné que nous procédons à la fusion de vos comptes Chaos et Enscape, veuillez choisir un nouveau mot de passe pour activer ou gérer vos produits et services.',
      'Since we are merging your Chaos and Axyz accounts, please choose a new password to activate or manage your products & services.':
        'Étant donné que nous procédons à la fusion de vos comptes Chaos et Axyz, veuillez choisir un nouveau mot de passe pour activer ou gérer vos produits et services.',
      'Since we are merging your Chaos and Corona accounts, please choose a new password to activate or manage your products & services.':
        'Étant donné que nous procédons à la fusion de vos comptes Chaos et Corona, veuillez choisir un nouveau mot de passe pour activer ou gérer vos produits et services.',
      'privacy-text-chaos':
        'En continuant, j’accepte la <privacy>politique de confidentialité</privacy>, <terms>les conditions d’utilisation</terms> et <eula>le CLUF</eula>',
      'privacy-text-axyz':
        'En continuant, j’accepte la <privacy>politique de confidentialité</privacy>, <terms>les conditions d’utilisation</terms> et <eula>le CLUF</eula>',
      'privacy-text-corona':
        'En continuant, j’accepte la <privacy>politique de confidentialité</privacy> et <terms>les conditions d’utilisation</terms>',
      'privacy-text-enscape': 'En continuant, j’accepte <eula>le CLUF</eula>',
      'privacy-text-trial':
        'En continuant, j’accepte la <privacy>politique de confidentialité</privacy>, <terms>les conditions d’utilisation</terms> et <eula>le CLUF</eula>',
      'Your account is temporary blocked due to security reasons. Please try again in one minute.':
        'Votre compte est temporairement bloqué pour des raisons de sécurité. Veuillez réessayer dans une minute.',
      'Sign in to get your trial': 'Connectez-vous pour profiter de votre version d’essai',
      'Create account to get your trial': 'Créez un compte pour profiter de votre version d’essai',
      'We could not sign you in. If the problem persists, please':
        'Nous n’avons pas pu vous authentifier. Si le problème persiste, veuillez',
      'Your user has been disabled in our system.': 'Votre utilisateur a été désactivé de notre système.',
      'Sign in to complete your purchase': 'Connectez-vous pour terminer votre achat',
      'Sign in to activate the Corona trial on your account':
        'Connectez-vous pour activer la version d’essai Corona sur votre compte',
      'Create account to complete your purchase': 'Créez un compte pour terminer votre achat',
      'Sign in complete.': 'Connexion terminée.',
      'You can now start using all Chaos products licensed to you':
        'Vous pouvez maintenant commencer à utiliser tous les produits Chaos qui vous ont été concédés sous licence',
      'You may now close this window.': 'Vous pouvez maintenant fermer cette fenêtre.',
      'An error has occurred during authentication. Try to sign in again.':
        'Une erreur s’est produite lors de l’authentification. Essayez de vous reconnecter.',
      'Still having problems signing in?': 'Vous rencontrez toujours des difficultés pour vous connecter?',
      'Visit our Help Center.': 'Visitez notre centre d’aide.',
      'Sign in with a different account?': 'Vous voulez vous connecter avec un autre compte?',
      'Go to chaos.com': 'Accédez à chaos.com',
      'You are now signed out of all Chaos products licensed to you':
        'Vous êtes maintenant déconnecté de tous les produits Chaos qui vous ont été concédés sous licence',
      'Sign out complete.': 'Déconnexion terminée.',
      'Work email': 'Adresse e-mail professionnelle',
      'You can organize and share your Enscape uploads, create Panorama Tours and manage licenses by accessing your account.':
        'Vous pouvez organiser et partager vos chargements Enscape, créer des visites panoramiques et gérer les licences en accédant à votre compte.',
      'Go to my.enscape3d.com': 'Accédez à my.enscape3d.com',
      'Authentication complete': 'Authentification terminée',
      'You may now close this window and proceed to the Enscape Application':
        'Vous pouvez maintenant fermer cette fenêtre et revenir à l’application Enscape',
      'You are now signed in as': 'Vous êtes maintenant connecté en tant que',
      'You may now close this window': 'Vous pouvez maintenant fermer cette fenêtre',
      'and proceed to the Enscape Application': 'et revenir à l’application Enscape',
      'Enter your email.': 'Saisissez votre adresse e-mail.',
      'You will use it to access your trial.': 'Elle vous servira à accéder à votre version d’essai.',
      'Work or educational email': 'Adresse e-mail professionnelle ou de votre établissement scolaire',
      'Create a Chaos account to start your free trial.': 'Créez un compte Chaos pour commencer votre essai gratuit.',
      'Your Chaos account has been created successfully.': 'Votre compte Chaos a bien été créé.',
      'Activation is required.': 'Activation requise.',
      'Check your email': 'Vérifiez votre messagerie',
      "We've sent an email to": 'Nous avons envoyé un e-mail à l’adresse',
      'with a link to activate your account and access your trial.':
        'avec un lien pour activer votre compte et accéder à votre version d’essai.',
      resend:
        'Si vous ne voyez pas l’e-mail, vérifiez votre dossier de spams ou essayez de <send>renvoyer l’e-mail</send>.',
      Continue: 'Continuer',
      'Your password should contain': 'Votre mot de passe doit contenir',
      'Change email': 'Modifier l’adresse e-mail',
      'Please enter a valid email.': 'Veuillez saisir une adresse e-mail valide.',
      'Confirm your email.': 'Confirmez votre adresse e-mail.',
      'Start your trial': 'Commencer votre essai',
      'What operating system will you use?': "Quel système d'exploitation utiliserez-vous?",
    },
  },
  it: {
    translation: {
      'Discourse Sign In': 'Accesso a Discourse',
      'Oops...': 'Oops...',
      'Continue with Google': 'Continua con Google',
      'Sign in with Trimble': 'Accedi con Trimble',
      'Continue with Trimble': 'Continua con Trimble',
      'Sign in with Facebook': 'Accedi con Facebook',
      'Simple, fast, smart cloud rendering': 'Cloud rendering semplice, veloce e smart',
      'Start with 20 free credits': 'Inizia con 20 crediti gratuiti',
      'A global computer graphics event for 3D artists and developers':
        'Un evento globale di computer graphics per gli artisti e gli sviluppatori 3D',
      'Get your ticket': 'Richiedi il tuo biglietto',
      'Epic CG event returns to Sofia': 'Il fantastico evento di CG fa ritorno a Sofia',
      'Early Bird tickets out now': 'Biglietti early bird ora disponibili',
      'Get tickets': 'Ottieni i biglietti',
      'New user?': 'Nuovo utente?',
      'Create Account': 'Crea un account',
      'Sign in': 'Registrati',
      'Please wait...': 'Attendere...',
      Password: 'Password',
      'Forgot your password?': 'Password dimenticata?',
      'Resend activation link': 'Invia di nuovo il link di attivazione',
      'A new activation link has been sent to': 'È stato inviato un nuovo link di attivazione a',
      'Please use it to activate your account.': 'Usalo per attivare il tuo account.',
      'Your account is active': 'Il tuo account è attivo',
      'Welcome to Chaos!': 'Chaos ti dà il benvenuto!',
      'Welcome to Corona!': 'Corona ti dà il benvenuto!',
      'Delete account': 'Elimina account',
      'Are you sure you want to delete all data associated with your account?':
        'Vuoi davvero eliminare tutti i dati associati all’account?',
      'Delete all data': 'Elimina tutti i dati',
      'Thank you!': 'Grazie!',
      'All data was successfully deleted.': 'Tutti i dati sono stati eliminati.',
      'We apologize for any inconvenience.': 'Ci scusiamo per qualsiasi inconveniente.',
      'Something went wrong.': 'Si è verificato un problema.',
      'Having troubles?': 'Stai avendo dei problemi?',
      'If you experience problems, contact our': 'In caso di problemi, contatta il nostro',
      'Customer Service.': 'servizio clienti.',
      'Already have an account?': 'Hai già un account?',
      'Back to profile': 'Torna al profilo',
      'Change your password': 'Modifica la tua password',
      'Old password': 'Vecchia password',
      'New password': 'Nuova password',
      'Change Password': 'Modifica la password',
      'Password successfully updated. Redirecting you to login page...':
        'Password aggiornata. Ti stiamo reindirizzando alla pagina di accesso…',
      "Enter your email and we'll": 'Inserisci il tuo indirizzo e-mail e ti',
      'send you instructions to reset your password': 'invieremo le istruzioni per reimpostare la password',
      Email: 'E-mail',
      Recover: 'Recupera',
      "with a link to reset your password. If you don't see the email, please check your spam folder.":
        'con un link per reimpostare la password. Controlla la cartella di posta indesiderata se non trovi l’e-mail.',
      'Back to login': 'Torna alla pagina di accesso',
      'LOADING...': 'CARICAMENTO IN CORSO…',
      'Request a new one': 'Richiedine uno nuovo',
      'Set your new password': 'Imposta la nuova password',
      '(12 or more characters)': '(almeno 12 caratteri)',
      Update: 'Aggiornamento',
      'Set your password': 'Imposta la password',
      'SET PASSWORD': 'IMPOSTA LA PASSWORD',
      'Leave page?\nChanges that you made may not be saved.':
        'Abbandonare la pagina?\nLe modifiche apportate potrebbero non essere salvate.',
      Logout: 'Disconnetti',
      'Edit profile': 'Modifica il profilo',
      'Your profile was updated!': 'Il tuo profilo è stato aggiornato!',
      'Go back to': 'Torna a',
      'chaos website': 'sito Web chaos',
      'corona website': 'sito Web corona',
      'enscape website': 'sito Web enscape',
      'Loading your account...': 'Caricamento del tuo account in corso…',
      'Loading your details...': 'Caricamento delle tue informazioni in corso…',
      'First name': 'Nome',
      'Last name': 'Cognome',
      '(Optional)': '(opzionale)',
      'Customer Number': 'Numero cliente',
      'Save changes': 'Salva le modifiche',
      'Change password': 'Modifica la password',
      'Reload captcha': 'Ricarica captcha',
      'Please enter the numbers on the image': 'Inserisci i numeri sull’immagine',
      Back: 'Indietro',
      'Almost there': 'Manca poco',
      'Create account': 'Crea un account',
      "Let's be friends. It's free.": 'Diventiamo amici. È gratis.',
      Next: 'Successivo',
      'Complete your profile': 'Completa il tuo profilo',
      'Country or Region': 'Stato o regione',
      newsletterSubscription:
        "Inviami notizie occasionali su offerte esclusive, prodotti, prossimi eventi e webinar. Puoi annullare l'iscrizione in qualsiasi momento.",
      'Your account has been created.': 'Il tuo account è stato creato.',
      "with a link to activate your account. If you don't see the email, please check your spam folder or try":
        'con un link per attivare il tuo account. Se non trovi l’e-mail, controlla la cartella di posta indesiderata o prova',
      sending: 'a inviare',
      'the email again.': 'nuovamente l’e-mail.',
      "We've resent the activation code successfully. If you still don't see the email, please":
        'Abbiamo rinviato il codice di attivazione. Se non trovi ancora l’e-mail,',
      contact: 'contatta',
      'our support team.': 'il nostro team di servizio.',
      'Link existing account': 'Collega un account esistente',
      'Account with this email already exists.': 'Esiste già un account con questa e-mail.',
      Change: 'Modifica',
      'Enter your Chaos password to link it to your': 'Inserisci la tua password di Chaos da associare al tuo',
      'account.': 'account.',
      'Chaos Password': 'Password di Chaos',
      'LINK ACCOUNTS': 'COLLEGA ACCOUNT',
      'We will use your Google details to create your Chaos account':
        'Useremo le tue informazioni di Google per creare il tuo account di Chaos',
      'We will use your Facebook details to create your Chaos account':
        'Useremo le tue informazioni di Facebook per creare il tuo account di Chaos',
      'We will use your Trimble details to create your Chaos account':
        'Useremo le tue informazioni di Trimble per creare il tuo account di Chaos',
      Country: 'Stato',
      'Link an existing account': 'Collega a un account esistente',
      'CREATE ACCOUNT': 'CREA UN ACCOUNT',
      'Account created': 'L’account è stato creato',
      'Your Chaos account is now active.': 'Il tuo account di Chaos è attivo.',
      OK: 'Ok',
      'A Chaos account has been created and linked to your':
        'È stato creato un account di Chaos ed è stato collegato al tuo',
      'Trimble Account.': 'account di Trimble.',
      'Fast interactive rendering. New, smart functionality.':
        'Rendering interattivo veloce. Nuove funzionalità intelligenti.',
      'Sign in with your Trimble account to start using V-Ray for SketchUp Studio':
        'Accedi con il tuo account di Trimble e inizia ad usare V-Ray per SketchUp Studio',
      'Loading...': 'Caricamento in corso…',
      '404 – PAGE NOT FOUND': '404 – PAGINA NON TROVATA',
      'Please enter your username or email address.': 'Inserisci il tuo nome utente o il tuo indirizzo e-mail.',
      'Invalid activation link.': 'Link di attivazione non valido.',
      'Invalid delete account link.': 'Link di eliminazione dell’account non valido.',
      'Please enter your email address.': 'Inserisci il tuo indirizzo e-mail.',
      'Please enter valid email address.': 'Immettere un indirizzo e-mail valido.',
      'Invalid password.': 'Password non valida.',
      'This email address is already connected to an account.': 'Questo indirizzo e-mail è già collegato a un account.',
      instead: 'invece',
      'Please enter your password.': 'Inserisci la tua password.',
      'Your password must be at least 8 characters long.': 'La password deve essere composta da almeno 8 caratteri.',
      'Your password must be at most 64 characters long.': 'La password deve essere composta da almeno 64 caratteri.',
      'Both lower and upper case letters': 'Sia minuscole che maiuscole',
      'At least one number': 'Almeno un numero',
      'At least one symbol': 'Almeno un simbolo',
      'At least 12 characters': 'Almeno 12 caratteri',
      'Please enter your first name.': 'Inserisci il tuo nome.',
      'Your first name cannot be more than 72 characters long.':
        'Il tuo nome non può essere composto da più di 72 caratteri.',
      'Please enter your last name.': 'Inserisci il tuo cognome.',
      'Your last name cannot be more than 53 characters long.':
        'Il tuo cognome non può essere composto da più di 53 caratteri.',
      'Please enter your country.': 'Inserisci il tuo Stato.',
      'Non-Latin letters': 'Lettere non appartenenti all’alfabeto latino',
      'No Internet connection. Please check the network cables, modem and router or reconnect to Wi-Fi.':
        'Nessuna connessione a Internet. Verifica i cavi di rete, il modem e il router o ricollegati al Wi-Fi.',
      'We are having technical difficulties. Please try later.':
        'Si sono verificati dei problemi tecnici. Riprovare più tardi.',
      'An unexpected error occurred.': 'Si è verificato un errore imprevisto.',
      'Username or email': 'Nome utente o e-mail',
      or: 'o',
      'Oops... what a mess.': 'Oops… che disastro.',
      Click: 'Clicca',
      here: 'qui',
      'to try again.': 'per riprovare.',
      'Show password': 'Mostra password',
      'Hide password': 'Nascondi password',
      'Continue with corporate email': 'Continua con l’e-mail aziendale',
      'Corporate sign in': 'Accesso aziendale',
      'Corporate email': 'E-mail aziendale',
      'Using a corporate account?': 'Stai usando un account aziendale?',
      'There was a problem finalizing your authentication.':
        'Si è verificato un problema durante la finalizzazione dell’autenticazione.',
      'Explore Cosmos': 'Scopri Cosmos',
      'Please choose a new password to access all Chaos products & services, including V-Ray, Corona, Vantage, and more.':
        'Scegli una nuova password per accedere a tutti i prodotti e servizi di Chaos, inclusi V-Ray, Corona, Vantage e molto altro.',
      'Since we are merging your Chaos accounts, please choose a new password to activate or manage your products & services.':
        'Since we are merging your Chaos accounts, please choose a new password to activate or manage your products & services.',
      'Since we are merging your Chaos and Enscape accounts, please choose a new password to activate or manage your products & services.':
        'Poiché stiamo unendo i tuoi account di Chaos e Enscape, scegli una nuova password per attivare o gestire i tuoi prodotti e servizi.',
      'Since we are merging your Chaos and Axyz accounts, please choose a new password to activate or manage your products & services.':
        'Poiché stiamo unendo i tuoi account di Chaos e Axyz, scegli una nuova password per attivare o gestire i tuoi prodotti e servizi.',
      'Since we are merging your Chaos and Corona accounts, please choose a new password to activate or manage your products & services.':
        'Poiché stiamo unendo i tuoi account di Chaos e Corona, scegli una nuova password per attivare o gestire i tuoi prodotti e servizi.',
      'privacy-text-chaos':
        'Proseguendo, accetto l’informativa <privacy>sulla privacy</privacy>, i <terms>termini e le condizioni</terms> d’uso e il <eula>CLUF</eula>',
      'privacy-text-axyz':
        'Proseguendo, accetto l’informativa <privacy>sulla privacy</privacy>, i <terms>termini e le condizioni</terms> d’uso e il <eula>CLUF</eula>',
      'privacy-text-corona':
        'Proseguendo, accetto l’informativa <privacy>sulla privacy</privacy>, i <terms>termini e le condizioni</terms>',
      'privacy-text-enscape': 'Proseguendo, accetto l’informativa il <eula>CLUF</eula>',
      'privacy-text-trial':
        'Proseguendo, accetto l’informativa <privacy>sulla privacy</privacy>, i <terms>termini e le condizioni</terms> d’uso e il <eula>CLUF</eula>',
      'Your account is temporary blocked due to security reasons. Please try again in one minute.':
        'Il tuo account è temporaneamente bloccato per ragioni di sicurezza. Riprova tra un minuto.',
      'Sign in to get your trial': 'Accedi per ottenere la versione di prova',
      'Create account to get your trial': 'Crea un account per ottenere la versione di prova',
      'We could not sign you in. If the problem persists, please':
        'Non è stato possibile effettuare l’accesso. Se il problema persiste,',
      'Your user has been disabled in our system.': 'Il tuo account utente è stato disattivato nel nostro sistema.',
      'Sign in to complete your purchase': 'Accedi per completare l’acquisto',
      'Sign in to activate the Corona trial on your account':
        'Accedi per attivare la versione di prova di Corona sul tuo account',
      'Create account to complete your purchase': 'Crea un account per completare l’acquisto',
      'Sign in complete.': 'Accesso completato.',
      'You can now start using all Chaos products licensed to you':
        'Ora puoi iniziare ad usare tutti i prodotti di Chaos per i quali ti è stata concessa la licenza',
      'You may now close this window.': 'Puoi chiudere questa finestra.',
      'An error has occurred during authentication. Try to sign in again.':
        'Si è verificato un errore durante l’autenticazione. Prova a effettuare di nuovo l’accesso.',
      'Still having problems signing in?': 'Hai ancora dei problemi con l’accesso?',
      'Visit our Help Center.': 'Visita la nostra Guida rapida.',
      'Sign in with a different account?': 'Accedere con un account diverso?',
      'Go to chaos.com': 'Vai su chaos.com',
      'You are now signed out of all Chaos products licensed to you':
        'È stata effettuata la disconnessione da tutti i prodotti di Chaos per i quali ti è stata concessa la licenza',
      'Sign out complete.': 'Disconnessione completata.',
      'Work email': 'E-mail di lavoro',
      'You can organize and share your Enscape uploads, create Panorama Tours and manage licenses by accessing your account.':
        'Puoi organizzare e condividere i tuoi caricamenti di Enscape, creare tour panoramici e gestire le licenze accedendo al tuo account.',
      'Go to my.enscape3d.com': 'Vai a my.enscape3d.com',
      'Authentication complete': 'Autenticazione completata',
      'You may now close this window and proceed to the Enscape Application':
        'Puoi chiudere questa finestra e proseguire nell’applicazione di Enscape',
      'You are now signed in as': 'Hai effettuato l’accesso come',
      'You may now close this window': 'Puoi chiudere questa finestra',
      'and proceed to the Enscape Application': 'e proseguire nell’applicazione di Enscape',
      'Enter your email.': 'Inserisci il tuo indirizzo e-mail.',
      'You will use it to access your trial.': 'Ti servirà per accedere alla tua versione di prova.',
      'Work or educational email': 'E-mail didattica o di lavoro',
      'Create a Chaos account to start your free trial.':
        'Crea un account di Chaos per iniziare la tua prova gratuita.',
      'Your Chaos account has been created successfully.': 'Il tuo account di Chaos è stato creato.',
      'Activation is required.': 'È richiesta l’attivazione.',
      'Check your email': 'Verifica la tua casella e-mail',
      "We've sent an email to": 'Ti abbiamo inviato un’e-mail a',
      'with a link to activate your account and access your trial.':
        'con un link per attivare il tuo account e accedere alla versione di prova.',
      resend:
        'Se non trovi l’e-mail, controlla la cartella di posta indesiderata o prova a <send>inviarla nuovamente</send>.',
      Continue: 'Continua',
      'Your password should contain': 'La tua password deve contenere',
      'Change email': 'Modifica e-mail',
      'Please enter a valid email.': 'Immettere un indirizzo e-mail valido.',
      'Confirm your email.': 'Conferma la tua e-mail.',
      'Start your trial': 'Inizia la tua prova gratuita',
    },
  },
};
